import React, {useState} from 'react';
import {signup, checkUsernameAvailability, checkEmailAvailability} from '../../util/APIUtils';
import './Signup.css';
import {Link} from 'react-router-dom';
import {validateUsername, validateName, validatePassword, validateEmail} from './SignupValidateUtils';

import { Input, Button, Form, notification } from 'antd';
import Title from "../../util/Title";

const FormItem = Form.Item;

const Signup = (props) => {
    const [name, setName] = useState({value: ''});
    const [surname, setSurname] = useState({value: ''});
    const [username, setUsername] = useState({value: ''});
    const [email, setEmail] = useState({value: ''});
    const [password, setPassword] = useState({value: ''});

    const handleInputChange = (event, validationFun, setState) => {
        const target = event.target;
        const inputValue = target.value;

        setState({
            value: inputValue,
            ...validationFun(inputValue)
        });
    };

    const handleOnFinish = values => {
        const signupRequest = {
            name: name.value,
            surname: surname.value,
            email: email.value,
            username: username.value,
            password: password.value
        };
        signup(signupRequest)
            .then(response => {
                notification.success({
                    message: 'Taxovia App',
                    description: "Ďakujeme! Úspešne ste sa zaregistrovali, na Váš email sme poslali ďalšie inštrukcie!",
                });
                props.history.push("/login");
            }).catch(error => {
                notification.error({
                    message: 'Taxovia App',
                    description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                });
        });
    };

    const isFormInvalid = () => {
        return !(name.validateStatus === 'success' &&
            username.validateStatus === 'success' &&
            email.validateStatus === 'success' &&
            password.validateStatus === 'success'
        );
    };

    const validateUsernameAvailability = () => {
        // First check for client side errors in username
        const usernameValue = username.value;
        const usernameValidation = validateUsername(usernameValue);

        if (usernameValidation.validateStatus === 'error') {
            setUsername({
                value: usernameValue,
                ...usernameValidation
            });
            return;
        }
        setUsername({
            value: usernameValue,
            validateStatus: 'validating',
            errorMsg: null
        });

        checkUsernameAvailability(usernameValue)
            .then(response => {
                if (response.available) {
                    setUsername({
                        value: usernameValue,
                        validateStatus: 'success',
                        errorMsg: null
                    });
                } else {
                    setUsername({
                        value: usernameValue,
                        validateStatus: 'error',
                        errorMsg: 'Prihlasovacie meno už je použité...'
                    });
                }
            }).catch(error => {
            // Marking validateStatus as success, Form will be recchecked at server
            setUsername({
                value: usernameValue,
                validateStatus: 'success',
                errorMsg: null
            });
        });
    };

    const validateEmailAvailability = () => {
        // First check for client side errors in email
        const emailValue = email.value;
        const emailValidation = validateEmail(emailValue);

        if (emailValidation.validateStatus === 'error') {
            setEmail({
                value: emailValue,
                ...emailValidation
            });
            return;
        }

        setEmail({
            value: emailValue,
            validateStatus: 'validating',
            errorMsg: null
        });

        checkEmailAvailability(emailValue)
            .then(response => {
                if (response.available) {
                    setEmail({
                        value: emailValue,
                        validateStatus: 'success',
                        errorMsg: null
                    });
                } else {
                    setEmail({
                        value: emailValue,
                        validateStatus: 'error',
                        errorMsg: 'Email už bol použitý/zaregistrovaný...'
                    });
                }
            }).catch(error => {
            // Marking validateStatus as success, Form will be recchecked at server
            setEmail({
                value: emailValue,
                validateStatus: 'success',
                errorMsg: null
            });
        });
    };

    const [form] = Form.useForm();
    return (
        <div className="signup-container">
            <Title pageTitle="Registrácia"/>
            <h2 className="page-title">Registrácia</h2>
            <div className="signup-content">
                <Form form={form} onFinish={handleOnFinish} className="signup-form">
                    <FormItem
                        label="Meno"
                        validateStatus={name.validateStatus}
                        help={name.errorMsg}>
                        <Input
                            size="large"
                            name="name"
                            autoComplete="off"
                            placeholder="Meno"
                            value={name.value}
                            onChange={(event) => handleInputChange(event, validateName, setName)}/>
                    </FormItem>
                    <FormItem
                        label="Priezvisko"
                        validateStatus={surname.validateStatus}
                        help={surname.errorMsg}>
                        <Input
                            size="large"
                            name="surname"
                            autoComplete="off"
                            placeholder="Priezvisko"
                            value={surname.value}
                            onChange={(event) => handleInputChange(event, validateName, setSurname)}/>
                    </FormItem>
                    <FormItem label="Prihlasovacie meno"
                              hasFeedback
                              validateStatus={username.validateStatus}
                              help={username.errorMsg}>
                        <Input
                            size="large"
                            name="username"
                            autoComplete="off"
                            placeholder="Unikátne prihlasovacie meno (nick)"
                            value={username.value}
                            onBlur={validateUsernameAvailability}
                            onChange={(event) => handleInputChange(event, validateUsername, setUsername)}/>
                    </FormItem>
                    <FormItem
                        label="Email"
                        hasFeedback
                        validateStatus={email.validateStatus}
                        help={email.errorMsg}>
                        <Input
                            size="large"
                            name="email"
                            type="email"
                            autoComplete="off"
                            placeholder="Váš email"
                            value={email.value}
                            onBlur={validateEmailAvailability}
                            onChange={(event) => handleInputChange(event, validateEmail, setEmail)}/>
                    </FormItem>
                    <FormItem
                        label="Heslo"
                        validateStatus={password.validateStatus}
                        help={password.errorMsg}>
                        <Input
                            size="large"
                            name="password"
                            type="password"
                            autoComplete="off"
                            placeholder="Heslo, dlhé 6 - 20 znakov"
                            value={password.value}
                            onChange={(event) => handleInputChange(event, validatePassword, setPassword)}/>
                    </FormItem>
                    <FormItem>
                        <Button type="primary"
                                htmlType="submit"
                                size="large"
                                className="signup-form-button"
                                disabled={isFormInvalid()}>Registrácia</Button>
                        Už ste zaregistrovaný? <Link to="/login">Prihláste sa!</Link>
                        <span className="forgot-pwd"><Link to="/forgot-password">Zabudnuté heslo</Link></span>
                    </FormItem>
                </Form>
            </div>
        </div>
    )
};

export default Signup;
