import React, {useEffect, useState} from 'react';
import {pairingAccountStatement, manualPairing} from "../util/APIUtils";
import {Card, notification, Table, Button} from "antd";

function AccountStatementsPairing({pairingRecord, onPairingSuccess}) {
    const [monthlyReport, setMonthlyReport] = useState();

    useEffect(() => {
        if (!pairingRecord) {
            return null;
        }

        pairingAccountStatement({pairingId: pairingRecord.id})
            .then(response => setMonthlyReport(response))
            .catch(() => {
                notification.error({
                    message: 'Taxovia App',
                    description: 'Problém s párovaním!'
                })
            })
    }, [pairingRecord]);

    if (!pairingRecord) {
        return null;
    }

    function handleOnClick(record) {
        const manualPairingRequest = {accountStatementId: pairingRecord.id, monthlyReportId: record.id};
        manualPairing(manualPairingRequest)
            .then(repsonse => {
                notification.success({
                    message: 'Taxovia App',
                    description: 'Spárované!'
                });
                if (onPairingSuccess) {
                    onPairingSuccess(manualPairingRequest);
                }
            })
            .catch(() => {
                notification.error({
                    message: 'Taxovia App',
                    description: 'Problém s párovaním!'
                })
            })
    }

    const columns = [
        {
            title: 'Meno',
            dataIndex: ['user', 'name'],
            key: 'name'
        },
        {
            title: 'Priezvisko',
            dataIndex: ['user', 'surname'],
            key: 'surname'
        },
        {
            title: 'Rok',
            dataIndex: 'year',
            key: 'year'
        },
        {
            title: 'Mesiac',
            dataIndex: 'month',
            key: 'month'
        },
        {
            title: 'VS',
            dataIndex: 'vs',
            key: 'vs'
        },
        {
            title: 'SS',
            dataIndex: 'ss',
            key: 'ss'
        },
        {
            title: 'Suma',
            dataIndex: 'sum',
            key: 'sum'
        },
        {
            title: 'IBAN',
            dataIndex: 'iban',
            key: 'iban'
        },
        {
            title: 'Akcia',
            key: 'action',
            render: (_, record) => <Button type="primary" onClick={() => handleOnClick(record)}>Párovať</Button>
        }
    ];

    return (<div>
        <Card title={pairingRecord.operation}>
            <p>IBAN odosielateľa: {pairingRecord.toAccountIban}</p>
            <p>Názov účtu: {pairingRecord.correspondingAccount}</p>
            <p>Suma: {pairingRecord.amount}</p>
            <p>Variabilný symbol: {pairingRecord.vs}</p>
            <p>Špecifický symbol: {pairingRecord.ss}</p>
            <p>Správa: {pairingRecord.message}</p>
            <p>Poznámka: {pairingRecord.note}</p>
        </Card>
        <h2>Návrhy na párovanie</h2>
        <Table
            columns={columns}
            dataSource={monthlyReport}
            rowKey={(rec) => rec.id}
        />
    </div>);
}

export default AccountStatementsPairing;
