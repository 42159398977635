import React from 'react';
import {withRouter} from 'react-router-dom';
import './ForgotPassword.css';
import {UserOutlined} from '@ant-design/icons';
import {Input, Button, notification, Form} from 'antd';
import {forgotPassword} from "../../util/APIUtils";
import Title from "../../util/Title";

const FormItem = Form.Item;

function ForgotPassword(props) {
    return (
        <div className="forgot-password-container">
            <Title pageTitle="Zabudnuté heslo"/>
            <h2 className="page-title">Zabudnuté heslo</h2>
            <div className="forgot-password-content">
                <div className="forgot-password-info">Zadajte prihlasovacie meno, alebo email a my Vám odošleme link na
                    reset hesla.
                </div>
                <ForgotPasswordForm onFinish={props.onFinish}/>
            </div>
        </div>
    );
}

function ForgotPasswordForm(props) {
    const [form] = Form.useForm();

    function handleOnFinish(values) {
        console.log('Values', values)
        forgotPassword(values)
            .then(response => {
                props.onFinish(response.message);
            }).catch(error => {
            console.log('error', error)
            if (error.status === 404) {
                notification.error({
                    message: 'Taxovia App',
                    description: 'Prihlasovacie meno, alebo heslo nie je správne. Skúste ešte raz!'
                });
            } else {
                notification.error({
                    message: 'Taxovia App',
                    description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                });
            }
        });
    }

    return (
        <Form form={form} onFinish={handleOnFinish} className="forgot-password-form">
            <FormItem name="usernameOrEmail"
                      rules={[{required: true, message: 'Zadajte prihlasovacie meno, alebo email!'}]}>
                <Input
                    prefix={<UserOutlined/>}
                    size="large"
                    name="usernameOrEmail"
                    placeholder="Prihlasovacie meno alebo email"/>
            </FormItem>
            <FormItem>
                <Button type="primary" htmlType="submit" size="large"
                        className="forgot-password-form-button">Odoslať</Button>
            </FormItem>
        </Form>
    );

}

export default withRouter(ForgotPassword);
