import React from "react";
import { EditOutlined } from '@ant-design/icons';
import {Button, Tooltip} from "antd";

const UpdateMenuRovButton = props => {

    const handlerMenuRovUpdate = () => {
        props.handlerMenuRovUpdate(props.menuRov)
    };

    return (
        <Tooltip title="Úprava menu">
            <Button type="default" onClick={handlerMenuRovUpdate} icon={<EditOutlined />}/>
        </Tooltip>
    );
};

export default UpdateMenuRovButton;
