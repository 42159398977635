import React, {useEffect, useState} from 'react';
import {getUserProfile} from '../../util/APIUtils';
import {Avatar} from 'antd';
import {getAvatarColor} from '../../util/Colors';
import {formatDate} from '../../util/Helpers';
import LoadingIndicator from '../../common/LoadingIndicator';
import './Profile.css';
import NotFound from '../../common/NotFound';
import ServerError from '../../common/ServerError';

const Profile = props => {
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [serverError, setServerError] = useState(false);

    const loadUserProfile = username => {
        setIsLoading(true);

        getUserProfile(username)
            .then(response => {
                setUser(response);
                setIsLoading(false);
            }).catch(error => {
            if (error.status === 404) {
                setIsLoading(false);
                setNotFound(true);
            } else {
                setServerError(true);
                setIsLoading(false);
            }
        });
    };

    useEffect(() => {
        const username = props.match.params.username;
        loadUserProfile(username);
    }, []);

    useEffect(() => {
        const username = props.match.params.username;
        loadUserProfile(username);
    }, [props.match.params.username]);

    if (isLoading) {
        return <LoadingIndicator/>;
    }

    if (notFound) {
        return <NotFound/>;
    }

    if (serverError) {
        return <ServerError/>;
    }

    return (
        <div className="profile">
            {
                user ? (
                    <div className="user-profile">
                        <div className="user-details">
                            <div className="user-avatar">
                                <Avatar className="user-avatar-circle"
                                        style={{backgroundColor: getAvatarColor(user.name)}}>
                                    {user.surname[0].toUpperCase()}
                                </Avatar>
                            </div>
                            <div className="user-summary">
                                <div className="full-name">{user.name} {user.surname}</div>
                                <div className="username">@{user.username}</div>
                                <div className="user-joined">
                                    Zaregistrovaný: {formatDate(user.joinedAt)}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    );
};

export default Profile;
