import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Row, Col} from "antd";
import './Ekasa.css'
import Display from "./Display";
import ClientInfoForm from "./ClientInfoForm";

class Ekasa extends Component {
    render() {
        return (
            <div className='ekasa-container'>
                <Row>
                    <Col span={12}>
                        <ClientInfoForm/>
                    </Col>
                    <Col span={12}>
                        <Display value='0.00'/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(Ekasa);
