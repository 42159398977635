import {
    EMAIL_MAX_LENGTH,
    PASSWORD_MAX_LENGTH,
    PASSWORD_MIN_LENGTH,
    USERNAME_MAX_LENGTH,
    USERNAME_MIN_LENGTH,
    NAME_MIN_LENGTH,
    NAME_MAX_LENGTH
} from "../../constants/";

// Validation Functions

export const validateUsername = (username) => {
    if (username.length < USERNAME_MIN_LENGTH) {
        return {
            validateStatus: 'error',
            errorMsg: `Prihlasovacie meno je krátke (Minimum je ${USERNAME_MIN_LENGTH} znakov.)`
        }
    } else if (username.length > USERNAME_MAX_LENGTH) {
        return {
            validationStatus: 'error',
            errorMsg: `prihlasovacie meno je príliš dlhé (Maximum je ${USERNAME_MAX_LENGTH} znakov.)`
        }
    } else {
        return {
            validateStatus: null,
            errorMsg: null
        }
    }
};

export const validatePassword = (password) => {
    if (password.length < PASSWORD_MIN_LENGTH) {
        return {
            validateStatus: 'error',
            errorMsg: `Heslo je veľmi krátke (Minimum je ${PASSWORD_MIN_LENGTH} znakov.)`
        }
    } else if (password.length > PASSWORD_MAX_LENGTH) {
        return {
            validationStatus: 'error',
            errorMsg: `Heslo je príliš dlhé (Maximum je ${PASSWORD_MAX_LENGTH} znakov.)`
        }
    } else {
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }
};

export const validateEmail = (email) => {
    if (!email) {
        return {
            validateStatus: 'error',
            errorMsg: 'Email nemôže byť prázdny'
        }
    }

    const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+');
    if (!EMAIL_REGEX.test(email)) {
        return {
            validateStatus: 'error',
            errorMsg: 'Email nie je správny'
        }
    }

    if (email.length > EMAIL_MAX_LENGTH) {
        return {
            validateStatus: 'error',
            errorMsg: `Email je príliš dlhý (Maximum je ${EMAIL_MAX_LENGTH} znakov)`
        }
    }

    return {
        validateStatus: null,
        errorMsg: null
    }
};

export const validateName = (name) => {
    if(name.length < NAME_MIN_LENGTH) {
        return {
            validateStatus: 'error',
            errorMsg: `Krátke (Potrebné minimálne ${NAME_MIN_LENGTH} znakov.)`
        }
    } else if (name.length > NAME_MAX_LENGTH) {
        return {
            validationStatus: 'error',
            errorMsg: `Veľmi dlhé (Maximum je ${NAME_MAX_LENGTH} znakov.)`
        }
    } else {
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }
};
