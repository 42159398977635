import React from "react";
import { DeleteOutlined } from '@ant-design/icons';
import {Button, notification, Popconfirm} from "antd";
import {deleteMenu} from "../util/APIUtils";

const MenuRovDelete = props => {
    const handlerMenuRovDelete = (event) => {
        event.preventDefault();
        deleteMenu(props.menuRovId)
            .then(response => {
                notification.success({
                    message: 'Taxovia App',
                    description: response.message,
                });
                props.handlerDeleteMenuItem();
            }).catch(error => {
            if (error.status === 401) {
                notification.error({
                    message: 'Taxovia App',
                    description: 'Nemáte právo niečo odstraňovať!'
                });
            } else {
                notification.error({
                    message: 'Taxovia App',
                    description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                });
            }
        });
    };

    return (
        <Popconfirm title="Určite odstrániť z jedálneho lístka?"
                    onConfirm={handlerMenuRovDelete}
                    okText="Áno"
                    cancelText="Nie">
            <Button type="default" icon={<DeleteOutlined />}/>
        </Popconfirm>
    );
};

export default MenuRovDelete;
