import React, {useState, useEffect} from 'react';
import AccountStatementTable from "./AccountStatementTable";
import {getAccountStatementList} from "../util/APIUtils";
import LoadingIndicator from "../common/LoadingIndicator";

function AccountStatementsList() {
    const [statements, setStatements] = useState([]);
    const [isLoading, setIsLoading] = useState();

    const loadAccountStatementList = () => {
        let promise = getAccountStatementList();

        if (!promise) {
            return;
        }

        setIsLoading(true);
        promise
            .then(response => {
                setStatements(response.slice());
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        loadAccountStatementList();
    }, [])

    if (isLoading) {
        return <LoadingIndicator/>;
    }

    return (<AccountStatementTable statements={statements} setStatements={setStatements}/>);
}

export default AccountStatementsList;
