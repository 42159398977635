import React, {Component} from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Table } from "antd";
import moment from 'moment';
import {FILTER_ACCOUNTING_OPERATION} from "../constants";
import './Credit.css'

const skLocale = require('moment/locale/sk');

class Credit extends Component {
    state = {
        filteredAccountingOperation: JSON.parse(localStorage.getItem(FILTER_ACCOUNTING_OPERATION)) || ['PRÍJEM'],
    };

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredAccountingOperation: filters.accountingOperation
        });
        localStorage.setItem(FILTER_ACCOUNTING_OPERATION, JSON.stringify(filters.accountingOperation));
    };

    render() {
        const columns = [{
            title: 'Dátum',
            dataIndex: 'realizationDate',
            key: 'year',
            render: (text, record) => ( moment(text).locale('sk',skLocale).format('D.M.YYYY') )
        }, {
            title: 'Operácia',
            dataIndex: 'accountingOperation',
            key: 'accountingOperation',
            filters: [{
                text: 'PRÍJEM',
                value: 'PRÍJEM',
            }, {
                text: 'VÝDAJ',
                value: 'VÝDAJ',
            }, {
                text: 'STORNO',
                value: 'STORNO',
            }],
            filterMultiple: true,
            onFilter: (value, record) => record.accountingOperation.indexOf(value) === 0,
            filteredValue: this.state.filteredAccountingOperation || null
        }, {
            title: 'Počiatočná suma',
            dataIndex: 'initialValue',
            key: 'initialValue',
            render: (text, record) => ( parseFloat(text).toFixed(2) + ' €' )
        }, {
            title: 'Suma',
            dataIndex: 'value',
            key: 'value',
            render: (text, record) => ( parseFloat(text).toFixed(2) + ' €' )
        }, {
            title: 'Konečná suma',
            dataIndex: 'finalValue',
            key: 'finalValue',
            render: (text, record) => ( parseFloat(text).toFixed(2) + ' €' )
        }, {
            title: 'Poznámka',
            dataIndex: 'comment',
            key: 'comment'
        }];

        return (
            <div>
                <h2>Kreditná história</h2>
                <Table
                    columns={columns}
                    rowKey={(record) => record.id}
                    dataSource={this.props.credit}
                    pagination={{pageSize: 15}}
                    onChange={this.handleChange}
                    locale={{emptyText: 'Žiadny záznam'}}
                    loading={{spinning: this.props.isLoading, indicator: <LoadingOutlined spin />}}
                    rowClassName={(record, index) => menuClassName(record)}
                />
            </div>
        );
    }
}

function menuClassName(record) {
    return (record.accountingOperation !== null ?
        (!record.comment.includes('STORNO') ?
            'credit-' + record.accountingOperation.toLocaleLowerCase() : 'credit-storno') :'credit'
    );
}

export default Credit;
