import React, {Component} from "react";
import {LoadingOutlined} from '@ant-design/icons';
import {Table, Button, Popconfirm, notification} from "antd";
import moment from 'moment';
import MonthlyReportExpanded from "./MonthlyReportExpanded";
import {generateMonthlyReport, sendFullMonthlyReport} from "../util/APIUtils";

const skLocale = require('moment/locale/sk');

function MonthlyReportList({monthlyReport, isLoading, isDetail, setIsLoading, setMonthlyReport}) {

    const handlerGenerate = () => {
        const monthlyReportRequest = {
            year: monthlyReport[monthlyReport.length - 1].year,
            month: monthlyReport[monthlyReport.length - 1].month
        };
        let promise = generateMonthlyReport(monthlyReportRequest);

        if (!promise) {
            return;
        }

        setIsLoading(true);

        promise
            .then(response => {
                setMonthlyReport(response.slice());
                setIsLoading(false);
                notification.success({
                    message: 'Taxovia App',
                    description: "Odoslané!",
                });
            })
            .catch(error => {
                setIsLoading(false);
                notification.error({
                    message: 'Taxovia App',
                    description: 'Problém s odoslaním!'
                });
            });
    };

    const handlerSendReport = () => {
        const monthlyReportRequest = {
            year: monthlyReport[monthlyReport.length - 1].year,
            month: monthlyReport[monthlyReport.length - 1].month
        };
        let promise = sendFullMonthlyReport(monthlyReportRequest);

        if (!promise) {
            return;
        }

        setIsLoading(true);

        promise
            .then(response => {
                setIsLoading(false);
                notification.success({
                    message: 'Taxovia App',
                    description: "Odoslané!",
                });
            })
            .catch(error => {
                notification.error({
                    message: 'Taxovia App',
                    description: 'Problém s odoslaním!'
                });
                setIsLoading(false);
            });
    };

    const columnsYearAndMonth = [{
        title: 'Rok',
        dataIndex: 'year',
        key: 'year'
    }, {
        title: 'Mesiac',
        dataIndex: 'month',
        key: 'month'
    }];
    const columnsUser = [{
        title: 'Priezvisko',
        dataIndex: ['user', 'surname'],
        key: 'user.surname'
    }, {
        title: 'Meno',
        dataIndex: ['user', 'name'],
        key: 'user.name'
    }];
    const columnsInfo = [{
        title: 'Suma',
        dataIndex: 'sum',
        key: 'sum',
        render: (text, record) => (parseFloat(text).toFixed(2) + ' €')
    }, {
        title: 'Splatnosť',
        dataIndex: 'dueDate',
        key: 'dueDate',
        render: (text, record) => (text !== null ? moment(text).locale('sk', skLocale).format('D.M.YYYY') : '')
    }, {
        title: 'Uhradené',
        dataIndex: 'pairedDate',
        key: 'pairedDate',
        render: (text, record) => (text !== null ? moment(text).locale('sk', skLocale).format('D.M.YYYY') : '')
    }, {
        title: 'VS',
        dataIndex: 'vs',
        key: 'vs'
    }, {
        title: 'ŠS',
        dataIndex: 'ss',
        key: 'ss'
    }];
    const columns = isDetail ? columnsYearAndMonth.concat(columnsInfo) : columnsUser.concat(columnsInfo);

    return (
        <>
            <Table
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={monthlyReport}
                expandedRowRender={record => <MonthlyReportExpanded record={record}/>}
                pagination={{pageSize: isDetail ? 10 : 35, position: "bottom"}}
                locale={{emptyText: 'Žiadny záznam'}}
                loading={{spinning: isLoading, indicator: <LoadingOutlined spin/>}}
            />
            {
                !isDetail ? (
                    <div className="row">
                        <Popconfirm title="Prepočítať posledné obdobie?" onConfirm={handlerGenerate}
                                    okText="Áno" cancelText="Nie">
                            <Button type="primary" className="report">Prepočítať</Button>
                        </Popconfirm>
                        <Popconfirm title="Odoslať report za posledné obdobie?" onConfirm={handlerSendReport}
                                    okText="Áno" cancelText="Nie">
                            <Button type="primary" className="report">Odoslať report</Button>
                        </Popconfirm>
                    </div>
                ) : null
            }
        </>
    )
        ;
}

export default MonthlyReportList;
