import {ACCESS_TOKEN} from '../constants';

const request = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    });

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response => {
                if (response.status === 401 || response.status === 403) {
                    return Promise.reject(response);
                }
                return response.json().then(json => {
                    if (!response.ok) {
                        return Promise.reject(json);
                    }
                    return json;
                })
            }
        );
};

export function login(loginRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/auth/signin",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}

export function signup(signupRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/auth/signup",
        method: 'POST',
        body: JSON.stringify(signupRequest)
    });
}

export function checkUsernameAvailability(username) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/user/checkUsernameAvailability?username=" + username,
        method: 'GET'
    });
}

export function checkEmailAvailability(email) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/user/checkEmailAvailability?email=" + email,
        method: 'GET'
    });
}


export function getCurrentUser() {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/user/me",
        method: 'GET'
    });
}

export function getUserProfile(username) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/users/" + username,
        method: 'GET'
    });
}

export function addMenu(menuItemRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/rov-menu",
        method: 'POST',
        body: JSON.stringify(menuItemRequest)
    });
}

export function updateMenu(menuItemRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/rov-menu",
        method: 'PUT',
        body: JSON.stringify(menuItemRequest)
    });
}

export function deleteMenu(menuRovId) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/rov-menu?id=" + menuRovId,
        method: 'DELETE',
    });
}

export function getAllMenuRovs() {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + '/rov-menu',
        method: 'GET'
    });
}

export function getAllMenuRovs4User(menuRovRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + '/rov-menu/order',
        method: 'POST',
        body: JSON.stringify(menuRovRequest)
    });
}

export function serve(serveRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/serve",
        method: 'POST',
        body: JSON.stringify(serveRequest)
    });
}

export function forgotPassword(forgotPassword) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/pwd-recovery",
        method: 'POST',
        body: JSON.stringify(forgotPassword)
    });
}

export function resetPassword(resetPassword) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/pwd-recovery",
        method: 'PUT',
        body: JSON.stringify(resetPassword)
    });
}

export function getServeStat() {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + '/serve',
        method: 'GET'
    });
}

export function getAllUsers() {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + '/user/all',
        method: 'GET'
    });
}

export function getOrderAndServeList(username) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + '/order/' + username + '/list',
        method: 'GET'
    });
}

export function getMonthlyReport(monthlyReportRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + '/monthly-report/' + monthlyReportRequest.year + '/' + monthlyReportRequest.month,
        method: 'GET'
    });
}

export function generateMonthlyReport(monthlyReportRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + '/monthly-report',
        method: 'POST',
        body: JSON.stringify(monthlyReportRequest)
    });
}

export function recalcMonthlyReport(monthlyReportRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + '/monthly-report',
        method: 'PUT',
        body: JSON.stringify(monthlyReportRequest)
    });
}

export function sendMonthlyReport(monthlyReportRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/monthly-report/send",
        method: 'POST',
        body: JSON.stringify(monthlyReportRequest)
    });
}

export function sendFullMonthlyReport(monthlyReportRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/monthly-report/send/full",
        method: 'POST',
        body: JSON.stringify(monthlyReportRequest)
    });
}

export function addCredit(creditRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/credit",
        method: 'POST',
        body: JSON.stringify(creditRequest)
    });
}

export function getCredit() {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + '/credit',
        method: 'GET'
    });
}

export function getCreditList() {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + '/credit/all',
        method: 'GET'
    });
}

export function getDepositList() {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + '/deposit/all',
        method: 'GET'
    });
}

export function getAccountStatementList() {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + '/account-statement/all',
        method: 'GET'
    });
}

export function validateRegistration(validateRegistration) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/registration-confirm",
        method: 'POST',
        body: JSON.stringify(validateRegistration)
    });
}

export function orderMenu(orderRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/order/user",
        method: 'POST',
        body: JSON.stringify(orderRequest)
    });
}

export function deleteOrder(rov, date, userId) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/order/user?rov=" + rov + "&date=" + date + "&userId=" + userId,
        method: 'DELETE',
    });
}

export function searchMenuItems(rov, description, itemId) {
    let query = "rov=" + rov + "&description=" + description;
    if (itemId) {
        query = query + "&itemId=" + itemId;
    }
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/rov-menu/search?" + query,
        method: 'GET',
    });
}

const requestMultipart = (options) => {
    const headers = new Headers({
        'Accept': 'application/json'
    });

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
};

export function uploadCsvDocument(formData) {
    return requestMultipart({
        url: process.env.REACT_APP_API_BASE_URL + "/upload",
        method: 'POST',
        body: formData
    });
}

export function pairingAccountStatement(pairingRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/pairing",
        method: 'POST',
        body: JSON.stringify(pairingRequest)
    });
}

export function manualPairing(manualPairingRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/manual-pairing",
        method: 'POST',
        body: JSON.stringify(manualPairingRequest)
    });
}

export function depositRequest(depositRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/deposit",
        method: 'POST',
        body: JSON.stringify(depositRequest)
    });
}

export function manualPairingDeposit(manualPairingRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/manual-pairing-deposit",
        method: 'POST',
        body: JSON.stringify(manualPairingRequest)
    });
}

export function manualDepositToCredit(depositRequest) {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/manual-deposit-to-credit",
        method: 'POST',
        body: JSON.stringify(depositRequest)
    });
}

export const getConfig = () => {
    return request({
        url: process.env.REACT_APP_API_BASE_URL + "/config",
        method: 'GET'
    });
};
