import React from 'react';
import {Component} from 'react';
import {withRouter} from 'react-router-dom';
import './Serve.css';
import NewServeForm from './NewServeForm';
import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Row, Col, Progress, notification, Table, Tabs } from 'antd';
import {getServeStat} from "../util/APIUtils";
import moment from 'moment';
const skLocale = require('moment/locale/sk');

const { TabPane } = Tabs;

class Serve extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            rov: '',
            itemId: '',
            itemQuantity: '',
            description: '',
            pagination: { pageSize: 4 },
            countOrders: 0,
            countServes: 0,
            percent: 0,
            ordersAndServes: [],
            serveList: [],
            orderSummary: [],
            activeKey: '1',
            warning: null
        };
        this.onServe = this.onServe.bind(this);
        this.onServeStat = this.onServeStat.bind(this);
        this.handlerOnError = this.handlerOnError.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onServeStat() {
        getServeStat()
            .then(response => {
                const serveList = response.serveList.slice(),
                    orderSummary = response.orderSummary.slice();

                this.setState({
                    countOrders: response.countOrders,
                    countServes: response.countServes,
                    percent: response.percent,
                    ordersAndServes: response.ordersAndServes.slice(),
                    serveList: serveList,
                    orderSummary: orderSummary,
                    pagination: {
                        pageSize: 4,
                        current: serveList.length > 0 ? Math.round((serveList.length + 2) / 4) : 0,
                        total: serveList.length
                    },
                    activeKey: '1'
                });
            }).catch(error => {
            if (error.status === 401) {
                notification.error({
                    message: 'Taxovia App',
                    description: error.message
                });
            } else {
                notification.error({
                    message: 'Taxovia App',
                    description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                });
            }
            this.props.onLoading(false);
        });
    }

    onServe(serveResponse) {
        this.setState({
            fullName: serveResponse.fullName,
            rov: serveResponse.rov,
            itemId: serveResponse.itemId,
            itemQuantity: serveResponse.itemQuantity,
            description: serveResponse.description,
            warning: serveResponse.messageType !== 'success' ? serveResponse.message : null
        });
        this.onServeStat();
    }

    handlerOnError(msg){
        this.setState({
            warning: msg,
            fullName: '',
            rov: '',
            itemId: '',
            itemQuantity: '',
            description: ''
        });
    }

    onChange(page) {
        this.setState({
            pagination: page
        });
    }

    render() {
        const columns = [{
            title: 'Menu',
            dataIndex: 'itemId',
            key: 'itemId',
        }, {
            title: 'Čas',
            dataIndex: 'serveDate',
            key: 'date',
            render: (text, record) => ( moment(text).locale('sk',skLocale).format('HH:mm'))
        }, {
            title: 'Meno',
            dataIndex: 'fullName',
            key: 'meno',
        }];

        const columns_orders = [{
            title: 'Menu',
            dataIndex: 'itemId',
            key: 'itemId',
            render: (text, record) => record.menu.itemId
        }, {
            title: 'Meno',
            dataIndex: 'name',
            key: 'meno',
            render: (text, record) => record.user.surname + ' ' + record.user.name
        }];

        return (
            <div className="serve-container">
                <Row>
                    <Col span={12}>
                        <NewServeForm onServe={this.onServe} onServeStat={this.onServeStat} handlerOnError={this.handlerOnError}/>
                        {
                            this.state.warning === null ? null : (
                                <Alert className="serve-alert"
                                       message="UPOZORNENIE:"
                                       description={this.state.warning}
                                       type="warning"
                                />
                            )
                        }
                        <div className="serve-rov">
                            {this.state.rov}
                        </div>
                        <div className="serve-name">
                            {this.state.fullName}
                        </div>
                    </Col>
                    {
                        this.state.ordersAndServes
                            .map(item =>
                                (
                                    <Col span={3} key={item.menu}>
                                        <div className="progress-bar">
                                            {item.serves > item.orders ?
                                                (<Progress
                                                    type="dashboard"
                                                    percent={item.percent} status="exception"
                                                />) :
                                                (<Progress
                                                    type="dashboard"
                                                    percent={item.percent}
                                                />)
                                            }
                                        </div>
                                        <div className="progress-bar-num">{item.menu}: {item.serves}/{item.orders}</div>
                                    </Col>
                                )
                            )
                    }
                </Row>
                <Row>
                    <Col span={12}>
                        <div className="serve-itemId">
                            <div>{this.state.itemId}</div>
                            <div className="serve-itemQuantity">{this.state.itemQuantity}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        {   this.state.serveList.length > 0 ? (
                                <Tabs defaultActiveKey="1" size="small" activeKey={this.state.activeKey}
                                      onChange={(key) => this.setState({activeKey: key})}>
                                    <TabPane tab="VYDANÁ STRAVA" key="1">
                                        <Table
                                            rowKey={(record) => record.id}
                                            dataSource={this.state.serveList}
                                            pagination={this.state.pagination}
                                            onChange={this.onChange}
                                            columns={columns}
                                            locale={{emptyText: 'Bez výdaja'}}
                                            loading={{spinning: false, indicator: <LoadingOutlined spin />}}
                                        />
                                    </TabPane>
                                    <TabPane tab="NEVYDANÁ STRAVA" key="2">
                                        <Table
                                            rowKey={(record) => record.id}
                                            dataSource={this.state.orderSummary}
                                            pagination={{pageSize: 4}}
                                            columns={columns_orders}
                                            locale={{emptyText: 'Prázdny zoznam'}}
                                            loading={{spinning: false, indicator: <LoadingOutlined spin />}}
                                        />
                                    </TabPane>
                                </Tabs>
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="serve-description">
                            {this.state.description}
                        </div>
                        {this.state.countOrders > 0 ? (
                            <div className="progress-bar">
                                <span>{this.state.countServes} / {this.state.countOrders}</span>
                                <Progress strokeLinecap="square" percent={this.state.percent}/>
                            </div>
                        ) : null}
                    </Col>
                </Row>
            </div>
        );
    }

}

export default withRouter(Serve);
