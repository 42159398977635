import React from "react";
import {Button} from "antd";

function UserDetailBtn({detail, handlerUserDetail}) {
    return (
        <Button type="default" onClick={() => handlerUserDetail(detail)}>Detail</Button>
    );
}

export default UserDetailBtn;
