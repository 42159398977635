import React, {useState} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Tag, Tooltip, Input } from 'antd';
import "./NewMenuRov.css"

const MenuTags = props => {
    const [tags, setTags] = useState(props.tags.reduce((acc, item) => {
        acc.push(item.name);
        return acc;
    },[]));
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState();

    const handleClose = removedTag => {
        const newTags = tags.filter(tag => tag !== removedTag);
        setTags(newTags);
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = e => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        let newTags = tags;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            newTags = [...tags, inputValue];
        }
        setTags(newTags);
        setInputVisible(false);
        setInputValue('');
    };

    return (
        <div className='tag-list'>
            {tags.map((tag, index) => {
                const isLongTag = tag.length > 20;
                const tagElem = (
                    <Tag key={tag} color="cyan" closable={true} onClose={() => handleClose(tag)}>
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                    </Tag>
                );
                return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                        {tagElem}
                    </Tooltip>
                ) : (
                    tagElem
                );
            })}
            {inputVisible && (
                <Input
                    type="text"
                    size="small"
                    style={{width: 78}}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            )}
            {!inputVisible && (
                <Tag onClick={showInput} style={{background: '#fff', borderStyle: 'dashed'}}>
                    <PlusOutlined /> Nový Tag
                </Tag>
            )}
        </div>
    );
};

export default MenuTags;
