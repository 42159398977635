import React, {Component} from 'react';
import { IdcardOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input } from "antd";
import './Ekasa.css'

const FormItem = Form.Item;

class ClientInfoForm extends Component {
    render() {
       const AntWrappedForm = Form.create()(RfidReaderForm)
       return (
            <div className='client-info-container'>
                <AntWrappedForm
                    onClientInfo={this.props.onClientInfo}
                    handlerOnError={this.props.handlerOnError}
                />
            </div>
        );
    }
}

class RfidReaderForm extends Component {

    componentDidMount() {
        this.rfidCode.focus();
    }

    handleFocus = () => {
        this.props.form.resetFields();
        this.rfidCode.focus();
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const clientFiscalRequest = { ...values};
                console.log(clientFiscalRequest);
                this.handleFocus();
            }
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form layout="inline" onSubmit={this.handleSubmit} className="ekasa-form">
                <FormItem>
                    {getFieldDecorator('rfidCode', {
                        rules: [{required: true, message: 'Zadajte kód rfid karty!'}],
                    })(
                        <Input
                            ref={(input) => {
                                this.rfidCode = input;
                            }}
                            prefix={<IdcardOutlined />}
                            size="large"
                            name="rfidCode"
                            placeholder="Kód RFID karty"
                        />
                    )}
                </FormItem>
                <FormItem>
                    <Button type="default"
                            htmlType="submit"
                            size="large"
                            className="ekasa-form-button">Čítaj</Button>
                </FormItem>
            </Form>
        );
    }
}

export default ClientInfoForm;