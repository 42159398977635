import React, {useState} from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {Button, Upload, notification, Divider} from 'antd';
import AccountStatementTable from "./AccountStatementTable";
import {uploadCsvDocument} from "../util/APIUtils";
import AccountStatementsList from "./AccountStatementsList";

function UploadButton({setStatements}) {
    const [defaultFileList, setDefaultFileList] = useState([]);

    const uploadDocument = async options => {
        if (!options) {
            return null;
        }
        const {onSuccess, onError, file} = options;

        const fmData = new FormData();
        fmData.append("file", file);
        uploadCsvDocument(fmData)
            .then(response => {
                    if (onSuccess) {
                        onSuccess("OK");
                    }
                    setStatements(response);
                }
            )
            .catch(err => {
                if (onError) {
                    onError({err});
                }
                notification.error({
                    message: 'Taxovia App',
                    description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                });
            })
    };

    const handleOnChange = ({file, fileList, event}) => {
        // console.log(file, fileList, event);
        setDefaultFileList(fileList);
    };

    return (
        <>
            <Upload
                accept="text/csv"
                onChange={handleOnChange}
                defaultFileList={defaultFileList}
                customRequest={uploadDocument}
            >
                <Button icon={<UploadOutlined/>}>Nahraj bankové pohyby</Button>
            </Upload>
        </>
    );
}

function AccountStatements() {
    const [statements, setStatements] = useState();

    return (
        <div>
            <h2>Bankové pohyby</h2>
            <AccountStatementsList/>
            <h2>Importované doklady</h2>
            <UploadButton setStatements={setStatements}/>
            <AccountStatementTable statements={statements} setStatements={setStatements}/>
        </div>
    );
}

export default AccountStatements;
