import React, {Component} from "react";
import './UserDetailExpanded.css';

function UserDetailExpanded({record}) {
        return (
            <table className="expandedTable">
                <tr>
                    <th colSpan="3" className="header">INFO</th>
                    <th colSpan="3" className="header">ADRESA</th>
                    <th colSpan="2" className="header">ORGANIZÁCIA</th>
                </tr>
                <tr>
                    <td className="popis">Nick</td>
                    <td className="popis">Email</td>
                    <td className="popis">IBAN</td>
                    <td className="popis">Ulica</td>
                    <td className="popis">PSČ</td>
                    <td className="popis">Mesto</td>
                    <td className="popis">Oddelenie</td>
                    <td className="popis">Org</td>
                </tr>
                <tr>
                    <td className="riadok">{record.username}</td>
                    <td className="riadok">{record.email}</td>
                    <td className="riadok">{record.iban}</td>

                    <td className="riadok">{record.address}</td>
                    <td className="riadok">{record.psc}</td>
                    <td className="riadok">{record.city}</td>

                    <td className="riadok">{record.department}</td>
                    <td className="riadok">{record.organization}</td>
                </tr>
            </table>
        );
}

export default UserDetailExpanded;
