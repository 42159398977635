import React, {useState} from 'react';
import {Table, Space, Button, Drawer} from 'antd';
import AccountStatementsPairing from "./AccountStatementsPairing";
import moment from "moment/moment";
const skLocale = require('moment/locale/sk');

function AccountStatementTable({statements, setStatements}) {
    const [open, setOpen] = useState(false);
    const [record, setRecord] = useState();

    const showDrawer = (record) => {
        setOpen(true);
        setRecord(record);
    };

    const onClose = () => {
        setOpen(false);
        setRecord(null);
    };

    function onSuccessPairing(rec){
        statements = statements.map(statement => {
            if (statement.id === rec.accountStatementId){
                statement.status = 'SPAROVANY';
            }
            return statement;
        })
        setStatements(statements);
        onClose();
    }

    const columns = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Dátum',
            dataIndex: 'dateIn',
            key: 'dateIn',
            render: (text, record) => (text !== null ? moment(text).locale('sk', skLocale).format('D.M.YYYY') : '')
        },
        {
            title: 'IBAN',
            dataIndex: 'toAccountIban',
            key: 'toAccountIban'
        },
        {
            title: 'Účet',
            dataIndex: 'correspondingAccount',
            key: 'correspondingAccount'
        },
        {
            title: 'Suma',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => (parseFloat(text).toFixed(2) + ' €')
        }, {
            title: 'VS',
            dataIndex: 'vs',
            key: 'vs'
        }, {
            title: 'ŠS',
            dataIndex: 'ss',
            key: 'ss'
        }, {
            title: 'Správa',
            dataIndex: 'message',
            key: 'message'
        },
        {
            title: 'Akcia',
            key: 'action',
            render: (_, record) => {
                return record.status !== 'SPAROVANY' ?
                    (
                        <Space size="middle">
                            <Button type="primary" onClick={() => showDrawer(record)}>Manual</Button>
                        </Space>) : null;
            },
        },
    ];

    return (
        <>
            <Drawer title="Párovanie platieb" width={960} placement="right" onClose={onClose} open={open}>
                <AccountStatementsPairing pairingRecord={record} onPairingSuccess={onSuccessPairing}/>
            </Drawer>
            <Table
                columns={columns}
                dataSource={statements}
                rowKey={(record) => record.id}
            />
        </>
    );
}

function AccountStatementTableWrapper({statements, setStatements}) {
    if (!statements) {
        return null;
    }

    return <AccountStatementTable statements={statements} setStatements={setStatements}/>;
}

export default AccountStatementTableWrapper;
