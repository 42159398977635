import React, {useState, useEffect} from 'react';
import './App.css';
import {
    Route,
    withRouter,
    Switch
} from 'react-router-dom';

import {getCurrentUser, getConfig} from '../util/APIUtils';
import {ACCESS_TOKEN} from '../constants';

import MenuRovList from '../menurov/MenuRovList';
import Login from '../user/login/Login';
import Signup from '../user/signup/Signup';
import Profile from '../user/profile/Profile';
import AppHeader from '../common/AppHeader';
import AppFooter from "../common/AppFooter";
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import PrivateRoute from '../common/PrivateRoute';
import Serve from '../serve/Serve';
import {Layout, notification} from 'antd';
import ForgotPassword from "../user/resetpwd/ForgotPassword";
import ResetPassword from "../user/resetpwd/ResetPassword";
import Statistika from "../accounting/Accounting";
import ValidationRegistration from "../user/validation/ValidationRegistration";
import Ekasa from '../fiscal/Ekasa';
import {ConfigProvider} from "../common/ConfigContext";

const {Content, Footer} = Layout;

const App = props => {
    const [config, setConfig] = useState({});
    const [currentUser, setCurrentUser] = useState();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    notification.config({
        placement: 'topRight',
        top: 70,
        duration: 3,
    });

    const loadCurrentUser = () => {
        setIsLoading(true);
        getCurrentUser()
            .then(response => {
                setCurrentUser(response);
                setIsAuthenticated(true);
                setIsLoading(false);
            }).catch(error => {
            setIsLoading(false);
        });
    };

    function loadConfig() {
        getConfig()
            .then(response => setConfig(response))
            .catch(error => setConfig({}))
    }


    useEffect(() => {
        loadCurrentUser();
    }, []);

    const handleLogout = (redirectTo = "/", notificationType = "success", description = "Úspešne sme Vás odhlásili.") => {
        localStorage.removeItem(ACCESS_TOKEN);

        setCurrentUser(null);
        setIsAuthenticated(false);

        props.history.push(redirectTo);

        notification[notificationType]({
            message: 'TaxoviaServeApp',
            description: description,
        });
        window.location.reload();
    };

    const handleLogin = () => {
        notification.success({
            message: 'TaxoviaServeApp',
            description: "Úspešne ste sa prihlásili.",
        });
        loadCurrentUser();
        loadConfig();
        props.history.push("/");
    };

    const handleFinish = message => {
        notification.success({
            message: 'TaxoviaServeApp',
            description: message,
        });
        props.history.push("/");
    };

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return (
        <Layout className="app-container">
            <AppHeader isAuthenticated={isAuthenticated}
                       currentUser={currentUser}
                       onLogout={handleLogout}/>

            <ConfigProvider config={config}>
                <Content className="app-content">
                    <div className="container">
                        <Switch>
                            <Route exact path="/"
                                   render={props => <MenuRovList isAuthenticated={isAuthenticated}
                                                                 currentUser={currentUser}
                                                                 handleLogout={handleLogout} {...props} />}>
                            </Route>
                            <Route path="/login" render={props => <Login onLogin={handleLogin} {...props} />}></Route>
                            <Route path="/signup" component={Signup}></Route>
                            <Route path="/forgot-password"
                                   render={props => <ForgotPassword onFinish={handleFinish} {...props} />}></Route>
                            <Route path="/reset"
                                   render={props => <ResetPassword onFinish={handleFinish} {...props} />}></Route>
                            <Route path="/registration-confirm"
                                   render={props => <ValidationRegistration
                                       onFinish={handleFinish} {...props} />}></Route>
                            <Route path="/users/:username"
                                   render={props => <Profile isAuthenticated={isAuthenticated}
                                                             currentUser={currentUser} {...props}  />}>
                            </Route>
                            <PrivateRoute authenticated={isAuthenticated} path="/serve" component={Serve}
                                          handleLogout={handleLogout}></PrivateRoute>
                            <PrivateRoute authenticated={isAuthenticated} path="/accounting"
                                          component={Statistika} handleLogout={handleLogout}></PrivateRoute>
                            <PrivateRoute authenticated={isAuthenticated} path="/e-kasa" component={Ekasa}
                                          handleLogout={handleLogout}></PrivateRoute>
                            <Route component={NotFound}></Route>
                        </Switch>
                    </div>
                </Content>
            </ConfigProvider>
            <Footer style={{textAlign: 'center'}}>
                <AppFooter/>
            </Footer>
        </Layout>
    );
};

export default withRouter(App);
