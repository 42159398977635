import React, {Component} from 'react';
import {getAllMenuRovs4User} from '../util/APIUtils';
import { LoadingOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import moment from 'moment';
import UserOrderButton from "./UserOrderButton";
import UserOrderDeleteButton from "./UserOrderDeleteButton";

const skLocale = require('moment/locale/sk');

class UserMenuRovList extends Component {
    state = {
        menuRovs: [],
        user: this.props.user,
        week: this.props.week,
        year: this.props.year,
        isLoading: this.props.isLoading
    };

    loadMenuRovList = () => {
        this.loadMenuRovListWithUserAndDate(this.state.user, this.state.week, this.state.year);
    };

    loadMenuRovListWithUserAndDate = (user, week, year) => {
        const menuRowRequest = {
            userId: user.id,
            week: week,
            year: year
        };
        let promise = getAllMenuRovs4User(menuRowRequest);

        if (!promise) {
            return;
        }

        this.setState({
            isLoading: true
        });

        promise
            .then(response => {
                this.setState({
                    menuRovs: response.menu.slice(),
                    isLoading: false
                })
            }).catch(error => {
            this.setState({
                isLoading: false
            })
        });

    };

    handlerOrderMenuItem = () => {
        this.loadMenuRovList();
    };

    componentWillMount() {
        this.loadMenuRovList();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isLoading: nextProps.isLoading,
            week: nextProps.week,
            year: nextProps.year,
            user: nextProps.user
        });
        this.loadMenuRovListWithUserAndDate(nextProps.user, nextProps.week, nextProps.year);
    }

    render() {
        const columns = [{
            title: 'Dátum',
            dataIndex: 'date',
            key: 'date',
            className: 'menu-date',
            render: (text, record) => (moment(text).locale('sk', skLocale).format('dddd DD.MM.YYYY'))
        }, {
            title: 'ROV',
            dataIndex: 'rov',
            key: 'rov',
            className: 'menu-rov',
            filters: [{
                text: 'RAŇAJKY',
                value: 'RAŇAJKY',
            }, {
                text: 'OBED',
                value: 'OBED',
            }, {
                text: 'VEČERA',
                value: 'VEČERA',
            }],
            filterMultiple: true,
            onFilter: (value, record) => record.rov.indexOf(value) === 0
        }, {
            title: 'Výber',
            dataIndex: 'itemId',
            key: 'itemId',
            className: 'menu-itemid',
        }, {
            title: 'Menu',
            dataIndex: 'description',
            key: 'description',
            className: 'menu-description'
        }, {
            title: 'Akcia',
            key: 'action',
            className: 'menu-action',
            render: (text, record) => (menuButton(record, this.props.user, this.handlerOrderMenuItem))
        }];

        return (
            <div className="menu-rov-container">
                {
                    (
                        <div>
                            <h1 className="page-title">Jedálny lístok</h1>
                                <Table
                                    rowKey={(record) => record.id}
                                    dataSource={this.state.menuRovs}
                                    pagination={{pageSize: 15}}
                                    columns={columns}
                                    locale={{emptyText: 'Nie je k dispozícii jedálny lístok'}}
                                    loading={{
                                        spinning: this.state.isLoading,
                                        indicator: <LoadingOutlined spin />
                                    }}
                                />
                        </div>
                    )
                }
            </div>
        );
    }
}

function menuButton(record, user, myHandler) {
    return (
        record.itemId !== '-' ?
            (record.order === null ?
                <UserOrderButton menuRovId={record.id}
                                 user={user}
                                 handlerOrderMenuItem={myHandler} /> :
                <UserOrderDeleteButton userId={user.id}
                                       rov={record.order.rov}
                                       date={record.order.date}
                                       handlerOrderMenuItem={myHandler}
                />)
            : null
    );
}

export default UserMenuRovList;
