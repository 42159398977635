import React, {Component} from "react";
import './MonthlyReportExpanded.css';

const MonthlyReportExpanded = ({record}) => {
        return (
            <table className="expandedTable">
                <thead>
                <tr>
                    <th colSpan="3" className="header">RAŇAJKY</th>
                    <th colSpan="3" className="header">OBED</th>
                    <th colSpan="3" className="header">VEČERA</th>
                    <th colSpan="2" className="header">CELKOM</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="popis">Počet</td>
                    <td className="popis">VÚC</td>
                    <td className="popis">Stravník</td>
                    <td className="popis">Počet</td>
                    <td className="popis">VÚC</td>
                    <td className="popis">Stravník</td>
                    <td className="popis">Počet</td>
                    <td className="popis">VÚC</td>
                    <td className="popis">Stravník</td>
                    <td className="popis">VÚC</td>
                    <td className="popis">Stravník</td>
                </tr>
                <tr>
                    <td className="number">{parseFloat(record.rSum).toFixed(1)}</td>
                    <td className="sum">{parseFloat(record.rGrantVuc).toFixed(2)}</td>
                    <td className="sum">{parseFloat(record.rPrice).toFixed(2)}</td>

                    <td className="number">{parseFloat(record.oSum).toFixed(1)}</td>
                    <td className="sum">{parseFloat(record.oGrantVuc).toFixed(2)}</td>
                    <td className="sum">{parseFloat(record.oPrice).toFixed(2)}</td>

                    <td className="number">{parseFloat(record.vSum).toFixed(1)}</td>
                    <td className="sum">{parseFloat(record.vGrantVuc).toFixed(2)}</td>
                    <td className="sum">{parseFloat(record.vPrice).toFixed(2)}</td>

                    <td className="sum">{parseFloat(
                        record.rGrantVuc + record.oGrantVuc + record.vGrantVuc
                    ).toFixed(2)}</td>
                    <td className="sum">{parseFloat(record.sum).toFixed(2)}</td>
                </tr>
                </tbody>
            </table>
        );
}

export default MonthlyReportExpanded;
