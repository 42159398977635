import React, {Component, useState, useEffect} from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Table } from "antd";
import {getCreditList} from "../util/APIUtils";
import LoadingIndicator from '../common/LoadingIndicator';
import moment from 'moment';

const skLocale = require('moment/locale/sk');

const CreditList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [creditList, setCreditList] = useState([]);

    const loadCreditList = () => {
        let promise = getCreditList();

        if (!promise) {
            return;
        }

        setIsLoading(true);
        promise
            .then(response => {
                setCreditList(response.slice());
                setIsLoading(false);
            }).catch(error => {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        loadCreditList()
    }, []);

    if (isLoading) {
        return <LoadingIndicator/>;
    }

    const columns = [{
        title: 'Priezvisko',
        dataIndex: ['user','surname'],
        key: 'surname'
    }, {
        title: 'Meno',
        dataIndex: ['user','name'],
        key: 'name'
    }, {
        title: 'Pozícia',
        dataIndex: ['user','orgPosition'],
        key: 'orgPosition',
        filters: [{
            text: 'ŠTUDENT',
            value: 'ŠTUDENT',
        }, {
            text: 'ZAMESTNANEC',
            value: 'ZAMESTNANEC',
        }],
        filterMultiple: false,
        onFilter: (value, record) => record.user.orgPosition.indexOf(value) === 0,
    }, {
        title: 'Organizácia',
        dataIndex: ['user','organization'],
        key: 'organization',
        filters: [{
            text: 'SOŠ',
            value: 'SOS',
        }, {
            text: 'EGT',
            value: 'EGT',
        }, {
            text: 'Taxovia',
            value: 'Taxovia s.r.o.',
        }],
        filterMultiple: false,
        onFilter: (value, record) => record.user.organization.indexOf(value) === 0,
    }, {
        title: 'Dlžná suma',
        dataIndex: 'finalValue',
        key: 'finalValue',
        render: (text, record) => ( parseFloat(text).toFixed(2) + ' €' )
    }, {
        title: 'Aktualizované',
        dataIndex: 'realizationDate',
        key: 'realizationDate',
        render: (text, record) => ( text !== null ? moment(text).locale('sk',skLocale).format('D.M.YYYY') : '')
    }];
    return (
        <div>
            <h2>Kredity v systéme</h2>
            <Table
                rowKey={(record) => record.id}
                dataSource={creditList}
                columns={columns}
                locale={{ emptyText: 'Nie je k dispozícii žiadny záznam!' }}
                loading={{ spinning: isLoading, indicator: <LoadingOutlined spin /> }}
            />
        </div>
    );
}

export default CreditList;
