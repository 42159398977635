import {Component} from 'react';
import React from 'react';
import './NewCredit.css';
import skSK from 'antd/lib/date-picker/locale/sk_SK';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, DatePicker, notification, InputNumber } from 'antd';
import {addCredit} from "../util/APIUtils";
const FormItem = Form.Item;
const { TextArea } = Input;

const dateFormat = 'DD.MM.YYYY';

class NewCredit extends Component {
    render() {
        const AntWrappedMenuForm = Form.create()(NewCreditForm);
        return (
            <div className="new-menu-container">
                <div className="new-menu-content">
                    <AntWrappedMenuForm user={this.props.user}
                        handleAfterSubmit={this.props.handleAfterSubmit} isLoading={this.props.isLoading}/>
                </div>
            </div>
        );
    }
}

class NewCreditForm extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                const creditRequest = {...values, userId: this.props.user.id};
                addCredit(creditRequest)
                    .then(response => {
                        notification.success({
                            message: 'Taxovia App',
                            description: response.message,
                        });
                        this.props.handleAfterSubmit();
                    }).catch(error => {
                        if(error.status === 401) {
                            notification.error({
                                message: 'Taxovia App',
                                description: 'Nemáte právo niečo pridávať!'
                            });
                        } else {
                            notification.error({
                                message: 'Taxovia App',
                                description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                            });
                        }
                });
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className="create-credit-form">
                <FormItem className="credit-form-row"
                          label="Dátum">
                    {getFieldDecorator('date', {
                        rules: [{
                            type: 'object',
                            required: true,
                            message: 'Musíte vybrať dátum!'
                        }],
                    })(
                        <DatePicker
                            locale={skSK}
                            format={dateFormat}
                        />
                    )}
                </FormItem>
                <FormItem className="credit-form-row"
                          label="Suma">
                    {getFieldDecorator('value', {
                        initialValue: 0,
                        rules: [{required: true, message: 'Musíte zadať hodnotu!'}],
                    })(
                        <InputNumber min={-999} max={999}
                                     precision={2} />
                    )}
                </FormItem>
                <FormItem className="credit-form-row" label='Popis finančnej operácie'>
                    {getFieldDecorator('comment', {
                        rules: [{required: true, message: 'Zadajte popis finančnej operácie!'}],
                    })(
                        <TextArea
                            placeholder="Vytvor popis finančnej operácie"
                            style={{fontSize: '16px'}}
                            autosize={{minRows: 2, maxRows: 10}}
                            name="comment" />
                    )}
                </FormItem>
                <FormItem className="credit-form-row">
                    <Button type="primary"
                            htmlType="submit"
                            size="large"
                            loading={this.props.isLoading}
                            className="create-menu-form-button">Odoslať platbu</Button>
                </FormItem>
            </Form>
        );
    }
}

export default NewCredit;
