import {Component} from "react";
import { FolderAddOutlined } from '@ant-design/icons';
import { Button, notification } from "antd";
import React from "react";
import {orderMenu} from "../util/APIUtils";

class UserOrderButton extends Component {

    handlerOrder = (event, quantity) => {
        event.preventDefault();
        const orderRequest = {
            userId: this.props.user.id,
            menuItemId: this.props.menuRovId,
            quantity: quantity
        };
        orderMenu(orderRequest)
            .then(response => {
                notification.success({
                    message: 'Taxovia App',
                    description: response.message,
                });
                this.props.handlerOrderMenuItem();
            }).catch(error => {
            if (error.status === 401) {
                notification.error({
                    message: 'Taxovia App',
                    description: 'Nemáte právo niečo odstraňovať!'
                });
            } else {
                notification.error({
                    message: 'Taxovia App',
                    description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                });
            }
        });
    };

    render() {
        return <>
            <Button type="default" onClick={e => this.handlerOrder(e, 1)}><FolderAddOutlined />Veľká porcia</Button>
            <Button type="default" onClick={e => this.handlerOrder(e, 0.5)}><FolderAddOutlined />Malá porcia</Button>
        </>;
    }
}

export default UserOrderButton;
