import React, {useState, useEffect} from "react";
import {LoadingOutlined} from '@ant-design/icons';
import {Table, Drawer} from "antd";
import {getAllUsers} from "../util/APIUtils";
import './UserList.css';
import UserDetailBtn from "./UserDetailBtn";
import UserDetail from "./UserDetail";
import UserDetailExpanded from "./UserDetailExpanded";

function UserList() {
    const [userList, setUserList] = useState([]);
    const [isLoading, setIsLoading] = useState();
    const [open, setOpen] = useState();
    const [user, setUser] = useState({});

    useEffect(() => loadUserList(), []);

    const loadUserList = () => {
        let promise = getAllUsers();
        if (!promise) {
            return;
        }

        setIsLoading(true);
        promise
            .then(response => {
                setIsLoading(false);
                setUserList(response.slice());
            }).catch(error => setIsLoading(false))
    };

    const showDrawer = (record) => {
        setOpen(true);
        setUser(record);
    };

    const onClose = () => {
        setOpen(false);
        setUser({});
    };

    const composeTitle = (user) => {
        return user.name + ' ' + user.surname + ' (' + user.username + '), ' +
            user.orgPosition + ', ' + user.department + ', ' + user.organization +
            ', (' + user.email + ')';
    };

    const columns = [{
        title: 'Priezvisko',
        dataIndex: 'surname',
        key: 'surname'
    }, {
        title: 'Meno',
        dataIndex: 'name',
        key: 'name'
    }, {
        title: 'Pozícia',
        dataIndex: 'orgPosition',
        key: 'orgPosition',
        filters: [{
            text: 'ŠTUDENT',
            value: 'ŠTUDENT',
        }, {
            text: 'ZAMESTNANEC',
            value: 'ZAMESTNANEC',
        }],
        filterMultiple: false,
        onFilter: (value, record) => record.orgPosition.indexOf(value) === 0,
    }, {
        title: 'Organizácia',
        dataIndex: 'organization',
        key: 'organization',
        filters: [{
            text: 'SOŠ',
            value: 'SOS',
        }, {
            text: 'EGT',
            value: 'EGT',
        }, {
            text: 'Taxovia',
            value: 'Taxovia s.r.o.',
        }],
        filterMultiple: false,
        onFilter: (value, record) => record.organization.indexOf(value) === 0,
    }, {
        title: 'Detail',
        key: 'detail',
        render: (text, record) => (
            <UserDetailBtn handlerUserDetail={showDrawer} detail={record}/>
        ),
    }];

    return (
        <div className="user-list-container">
            <Drawer
                title={composeTitle(user)}
                width={960}
                placement="left"
                closable={true}
                onClose={onClose}
                open={open}
                className='drawer'
            >
                <UserDetail detail={user}/>
            </Drawer>
            <Table
                rowKey={(record) => record.id}
                dataSource={userList}
                columns={columns}
                locale={{emptyText: 'Nie je k dispozícii žiadny záznam!'}}
                loading={{spinning: isLoading, indicator: <LoadingOutlined spin/>}}
                expandable={{
                    expandedRowRender: (record) => <UserDetailExpanded record={record}/>
                }}
            />
        </div>
    );

}

export default UserList;
