import React from 'react';
import { Button, Result } from 'antd';

const ServerError = () => {
    return (
        <Result
            status="500"
            title="500"
            subTitle="Oops! Na serveri sa nám niečo pokazilo. Skúste sa vrátiť o krok späť?"
            extra={<Button type="primary">Späť</Button>}
        />
    );
};

export default ServerError;
