import React from 'react';
import {Component} from 'react';
import {withRouter} from 'react-router-dom';
import './ValidateRegistration.css';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, notification } from 'antd';
import {validateRegistration} from "../../util/APIUtils";
import queryString from 'qs';

const FormItem = Form.Item;

class ValidationRegistration extends Component {
    render() {
        const values = queryString.parse(this.props.location.search.slice(1));
        const AntWrappedValidationRegistrationForm = Form.create()(ValidationRegistrationForm);
        return (
            <div className="validation-registration-container">
                <h1 className="page-title">Overenie registrácie</h1>
                <div className="validation-registration-content">
                    <AntWrappedValidationRegistrationForm onFinish={this.props.onFinish} token={values.token}/>
                </div>
            </div>
        );
    }
}

class ValidationRegistrationForm extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.form.validateFields((err) => {
            if (!err) {
                const validateRegistrationRequest = {
                    token: this.props.token
                };
                validateRegistration(validateRegistrationRequest)
                    .then(response => {
                        this.props.onFinish(response.message);
                    }).catch(error => {
                        if (error.status === 404) {
                            notification.error({
                                message: 'Taxovia App',
                                description: error.message
                            });
                        } else {
                            notification.error({
                                message: 'Taxovia App',
                                description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                            });
                        }
                });
            }
        });
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit} className="validation-registration-form">
                <FormItem>
                    <p>Kliknutím na tlačítko overíte Vašu registráciu</p>
                    <Button type="primary" htmlType="submit" size="large"
                            className="validation-registration-form-button">Overenie registrácie</Button>
                </FormItem>
            </Form>
        );
    }

}

export default withRouter(ValidationRegistration);
