import React from 'react';
import './NewMenuRov.css';
import skSK from 'antd/lib/date-picker/locale/sk_SK';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, DatePicker, Radio, notification } from 'antd';
import {updateMenu} from "../util/APIUtils";
import moment from 'moment';
import MenuTags from "./MenuTags";

const FormItem = Form.Item;
const {TextArea} = Input;

const dateFormat = 'DD.MM.YYYY';

const UpdateMenuRov = props => {
    const AntWrappedMenuForm = Form.create()(UpdateMenuForm);
    return (
        <div className="edit-menu-container">
            <h1 className="page-title">Úprava menu</h1>
            <div className="edit-menu-content">
                <AntWrappedMenuForm handleAfterSubmit={props.handleAfterSubmit} menuRov={props.menuRov}/>
            </div>
        </div>
    );
};

const UpdateMenuForm = props => {
    const handleSubmit = (event) => {
        event.preventDefault();

        props.form.validateFields((err, values) => {
            if (!err) {
                const menuItemRequest = Object.assign({},
                    {id: props.menuRov.id},
                    values
                );
                updateMenu(menuItemRequest)
                    .then(response => {
                        notification.success({
                            message: 'Taxovia App',
                            description: "Ďakujeme! Aktualizovali ste menu!",
                        });
                        props.handleAfterSubmit();
                    }).catch(error => {
                    if (error.status === 401) {
                        notification.error({
                            message: 'Taxovia App',
                            description: 'Nemáte právo niečo pridávať!'
                        });
                    } else {
                        notification.error({
                            message: 'Taxovia App',
                            description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                        });
                    }
                });
            }
        });
    };

    const convertRov = (rov) => {
        switch (rov) {
            case 'RAŇAJKY':
                return 'RANAJKY';
            case 'VEČERA':
                return 'VECERA';
            default:
                return rov;
        }
    };

    const {getFieldDecorator} = props.form;
    return (
        <Form onSubmit={handleSubmit} className="create-menu-form">
            <FormItem className="menu-form-row"
                      label="Dátum">
                {getFieldDecorator('date', {
                    initialValue: moment(props.menuRov.date),
                    rules: [{
                        type: 'object',
                        required: true,
                        message: 'Musíte vybrať dátum!'
                    }],
                })(
                    <DatePicker
                        locale={skSK}
                        format={dateFormat}
                    />
                )}
            </FormItem>
            <FormItem className="menu-form-row"
                      label="ROV">
                {getFieldDecorator('rov', {
                    initialValue: convertRov(props.menuRov.rov),
                    rules: [{required: true, message: 'Musíte vybrať obdobie!'}],
                })(
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value="RANAJKY">Raňajky</Radio.Button>
                        <Radio.Button value="OBED">Obed</Radio.Button>
                        <Radio.Button value="VECERA">Večera</Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem className="menu-form-row"
                      label="Menu">
                {getFieldDecorator('itemId', {
                    initialValue: props.menuRov.itemId,
                    rules: [{required: true, message: 'Musíte vybrať menu!'}],
                })(
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value="A">A</Radio.Button>
                        <Radio.Button value="B">B</Radio.Button>
                        <Radio.Button value="C">C</Radio.Button>
                        <Radio.Button value="Š">Š</Radio.Button>
                        <Radio.Button value="-">Polievka</Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem className="menu-form-row">
                {getFieldDecorator('description', {
                    initialValue: props.menuRov.description,
                    rules: [{required: true, message: 'Zadajte popis menu!'}],
                })(
                    <TextArea
                        placeholder="Vytvor popis menu"
                        style={{fontSize: '16px'}}
                        autoSize={{minRows: 5, maxRows: 10}}
                        name="description"/>
                )}
            </FormItem>
            <MenuTags tags={props.menuRov.tags}/>
            <FormItem className="menu-form-row">
                <Button type="primary"
                        htmlType="submit"
                        size="large"
                        className="create-menu-form-button">Aktualizuj menu</Button>
            </FormItem>
        </Form>
    );
};

export default UpdateMenuRov;
