import React, { Component } from 'react';
import {
    Link,
    withRouter
} from 'react-router-dom';
import './AppHeader.css';

import {
    CalculatorOutlined,
    DashboardOutlined,
    DownOutlined,
    ExportOutlined,
    HomeOutlined,
    UserOutlined,
} from '@ant-design/icons';

import { Layout, Menu, Dropdown } from 'antd';
const Header = Layout.Header;

class AppHeader extends Component {
    constructor(props) {
        super(props);   
        this.handleMenuClick = this.handleMenuClick.bind(this);   
    }

    handleMenuClick({ key }) {
      if(key === "logout") {
        this.props.onLogout();
      }
    }

    render() {
        let menuItems;
        if (this.props.currentUser) {
            menuItems = [
                <Menu.Item key="/">
                    <Link to="/">
                        <HomeOutlined className="nav-icon" /> Menu
                    </Link>
                </Menu.Item>,
                <Menu.Item key="/serve">
                    <Link to="/serve">
                        <ExportOutlined className="nav-icon" /> Výdaj
                    </Link>
                </Menu.Item>,
                <Menu.Item key="/accounting">
                    <Link to="/accounting">
                        <DashboardOutlined className="nav-icon" /> Administrácia
                    </Link>
                </Menu.Item>,
                // <Menu.Item key="/e-kasa">
                //     <Link to="/e-kasa">
                //         <CalculatorOutlined className="nav-icon" />
                //     </Link>
                // </Menu.Item>,
                <Menu.Item key="/profile" className="profile-menu">
                    <ProfileDropdownMenu
                        currentUser={this.props.currentUser}
                        handleMenuClick={this.handleMenuClick}/>
                </Menu.Item>
            ];
        } else {
          menuItems = [
            <Menu.Item key="/login">
              <Link to="/login">Prihlásenie</Link>
            </Menu.Item>,
            <Menu.Item key="/signup">
              <Link to="/signup">Registrácia</Link>
            </Menu.Item>                  
          ];
        }

        return (
            <Header className="app-header">
                <div className="container">
                    <div className="app-header-flex">
                        <div className="app-title">
                            <Link to="/"
                                  title={process.env.REACT_APP_NAME + '-' + process.env.REACT_APP_VERSION}>&tau;axovia</Link>
                        </div>
                        <Menu
                            theme="dark"
                            className="app-name"
                            mode="horizontal"
                            triggerSubMenuAction="click"
                            style={{lineHeight: '64px'}}
                            defaultSelectedKeys={['/']}
                            selectedKeys={[location.pathname]}>
                            {menuItems}
                        </Menu>
                    </div>
                </div>
            </Header>
        );
    }
}

function ProfileDropdownMenu(props) {
  const dropdownMenu = (
    <Menu onClick={props.handleMenuClick} className="profile-dropdown-menu">
      <Menu.Item key="user-info" className="dropdown-item" disabled>
        <div className="user-full-name-info">
          {props.currentUser.name + props.currentUser.surname}
        </div>
        <div className="username-info">
          @{props.currentUser.username}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="profile" className="dropdown-item">
        <Link to={`/users/${props.currentUser.username}`}>Profil</Link>
      </Menu.Item>
      <Menu.Item key="logout" className="dropdown-item">
        Odhlásenie
      </Menu.Item>
    </Menu>
  );

  return (
      <Dropdown 
        overlay={dropdownMenu} 
        trigger={['click']}
        getPopupContainer = { () => document.getElementsByClassName('profile-menu')[0]}>
        <a className="ant-dropdown-link" href="/">
           <UserOutlined className="nav-icon" style={{marginRight: 0}} /> <DownOutlined />
        </a>
      </Dropdown>
  );
}


export default withRouter(AppHeader);
