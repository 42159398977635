import React from 'react';
import {Layout} from "antd";

const Footer = Layout.Footer;
const AppFooter = () => {
    return (
        <Footer>
            <span>{'© 2024 Prolog K (' + process.env.REACT_APP_VERSION + ')'}</span>
        </Footer>
    );
};

export default AppFooter;
