import React from 'react';
import {withRouter} from 'react-router-dom';
import './ResetPassword.css';
import {CodeOutlined, LockOutlined} from '@ant-design/icons';
import {Input, Button, Form, notification} from 'antd';
import {resetPassword} from "../../util/APIUtils";
import queryString from 'qs';
import Title from "../../util/Title";

const FormItem = Form.Item;

function ResetPassword(props) {
    const values = queryString.parse(props.location.search.slice(1));
    return (
        <div className="reset-password-container">
            <Title pageTitle="Reset hesla"/>
            <h2 className="page-title">Reset hesla</h2>
            <div className="reset-password-content">
                <ResetPasswordForm onFinish={props.onFinish} token={values.token}/>
            </div>
        </div>
    );
}

function ResetPasswordForm(props) {
    const [form] = Form.useForm();

    function handleSubmit(values) {
        const {password, confirm} = form.getFieldsValue();
        if (password !== confirm) {
            notification.error({
                message: 'Taxovia App',
                description: 'Heslá nie sú zhodné!'
            });

        } else {
            const resetPasswordRequest = {
                token: props.token,
                ...values
            };
            resetPassword(resetPasswordRequest)
                .then(response => {
                    props.onFinish(response.message);
                }).catch(error => {
                if (error.status === 404) {
                    notification.error({
                        message: 'Taxovia App',
                        description: error.message
                    });
                } else {
                    notification.error({
                        message: 'Taxovia App',
                        description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                    });
                }
            });
        }
    }

    return (
        <Form form={form} onFinish={handleSubmit} className="reset-password-form">
            <FormItem name="password"
                      rule={[{required: true, message: 'Prosím zadajte heslo!'}, {validator: validatePassword}]}>
                <Input
                    prefix={<LockOutlined/>}
                    size="large"
                    name="password"
                    type="password"
                    placeholder="Heslo"/>
            </FormItem>
            <FormItem name="confirm" rule={[{required: true, message: 'Prosím potvrdte zadané  heslo!'}]}>
                <Input
                    prefix={<CodeOutlined/>}
                    size="large"
                    name="confirm"
                    type="password"
                    placeholder="Potvrdenie hesla"/>
            </FormItem>
            <FormItem>
                <Button type="primary" htmlType="submit" size="large"
                        className="reset-password-form-button">Odoslať</Button>
            </FormItem>
        </Form>
    );

}

const validatePassword = (rule, value, callback) => {
    if (value && value.length < 6) {
        callback("Heslo musí mať aspoň 6 znakov!");
    } else {
        callback();
    }
};

export default withRouter(ResetPassword);
