import React, {Component} from "react";
import {getOrderAndServeList, recalcMonthlyReport, sendMonthlyReport} from "../util/APIUtils";
import skSK from "antd/lib/date-picker/locale/sk_SK";
import { LoadingOutlined } from '@ant-design/icons';
import { Tabs, Table, Button, Popconfirm, notification, Drawer, DatePicker } from "antd";
import moment from 'moment';
import MonthlyReportList from "../monthlyreport/MonthlyReportList";
import Credit from "../invoice/Credit";
import './UserDetail.css';
import NewCredit from "../invoice/NewCredit";
import UserMenuRovList from "../menurov/UserMenuRovList";
import DepositTable from "./DepositTable";

const skLocale = require('moment/locale/sk');
const TabPane = Tabs.TabPane;
const { WeekPicker } = DatePicker;

class UserDetail extends Component {
    state = {
        user: null,
        orderServeList: [],
        monthlyReport: [],
        credit: [],
        deposit: [],
        bank: null,
        isLoading: false,
        filteredRovValue: [],
        platbaDrawer: false
    };

    componentWillMount() {
        moment.locale('sk',skLocale);
        moment.weekdays(true);
        this.loadOrderAndServeList(this.props.detail.username);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.detail !== nextProps.detail && nextProps.detail.username) {
            this.loadOrderAndServeList(nextProps.detail.username);
        }
    }

    loadOrderAndServeList = (username) => {
        let promise = getOrderAndServeList(username);

        if (!promise) {
            return;
        }

        this.setState({
            isLoading: true
        });

        promise
            .then(response => {
                this.setState({
                    user: response.user,
                    orderServeList: response.report.slice(),
                    monthlyReport: response.monthlyReport.slice(),
                    credit: response.credit.slice(),
                    deposit: response.deposit.slice(),
                    bank: response.bank,
                    isLoading: false
                })
            }).catch(error => {
            this.setState({
                isLoading: false
            })
        });

    };

    onChangeTab = (key) => {
        console.log(key, this.props.detail);
    };

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredRovValue: filters.rov
        });
    };

    handleRecalcReport = () => {
        const monthlyReportRequest = {
            username: this.state.user.username,
            year: this.state.monthlyReport[this.state.monthlyReport.length - 1].year,
            month: this.state.monthlyReport[this.state.monthlyReport.length - 1].month
        };
        let promise = recalcMonthlyReport(monthlyReportRequest);

        if (!promise) {
            return;
        }

        this.setState({
            isLoading: true
        });

        promise
            .then(response => {
                let monthlyReport = this.state.monthlyReport;
                monthlyReport.splice(-1, 1);
                monthlyReport.push(response);

                this.setState({
                    monthlyReport: monthlyReport,
                    isLoading: false
                });
                notification.success({
                    message: 'Taxovia App',
                    description: "Prepočítané!",
                });
            }).catch(error => {
                notification.error({
                    message: 'Taxovia App',
                    description: 'Problém s prepočítaním!'
                });
                this.setState({
                    isLoading: false
                })
        });
    };

    handleSendReport = () => {
        const monthlyReportRequest = {
            username: this.state.user.username,
            year: this.state.monthlyReport[this.state.monthlyReport.length - 1].year,
            month: this.state.monthlyReport[this.state.monthlyReport.length - 1].month
        };
        let promise = sendMonthlyReport(monthlyReportRequest);

        if (!promise) {
            return;
        }

        this.setState({
            isLoading: true
        });

        promise
            .then(response => {
                this.setState({
                    isLoading: false
                });
                notification.success({
                    message: 'Taxovia App',
                    description: "Odoslané!",
                });
            }).catch(error => {
            notification.error({
                message: 'Taxovia App',
                description: 'Problém s odoslaním!'
            });
            this.setState({
                isLoading: false
            })
        });
    };

    showPlatbaDriver = () => {
        this.setState({
            platbaDrawer: true
        })
    };

    onPlatbaDriverClose = () => {
        this.setState({
            platbaDrawer: false
        });
    };

    onNewCredit = () => {
        this.onPlatbaDriverClose();
        this.loadOrderAndServeList(this.props.detail.username);
    };

    onWeekChange = (date, dateString) => {
        if (date !== null) {
            this.setState({
                week: date.week(),
                year: date.year()
            });
        }
    };

    render() {
        const columns = [{
            title: 'Dátum',
            dataIndex: ['order','date'],
            key: 'date',
            render: (text, record) => (moment(text).locale('sk', skLocale).format('D.M.YYYY'))
        }, {
            title: 'ROV',
            dataIndex: 'order.rov',
            key: 'rov',
            filters: [{
                text: 'RAŇAJKY',
                value: 'RAŇAJKY',
            }, {
                text: 'OBED',
                value: 'OBED',
            }, {
                text: 'VEČERA',
                value: 'VEČERA',
            }],
            filterMultiple: true,
            onFilter: (value, record) => record.order.rov.indexOf(value) === 0,
            filteredValue: this.state.filteredRovValue || null
        }, {
            title: 'Výber',
            dataIndex: ['order','menu','itemId'],
            key: 'itemId'
        }, {
            title: 'Porcia',
            dataIndex: ['order','quantity'],
            key: 'quantity'
        }, {
            title: 'Menu',
            dataIndex: ['order','menu','description'],
            key: 'description'
        }, {
            title: 'Vydané',
            dataIndex: ['serve','serveDate'],
            key: 'serve',
            render: (text, record) => (
                record.serve !== null ? (moment(text).locale('sk', skLocale).format('HH:mm')) : '-'
            )
        }];

        return (
            <Tabs defaultActiveKey="1" onChange={this.onChangeTab}>
                <TabPane tab="Rekapitulácia" key="1">
                    <Credit credit={this.state.credit} isLoading={this.state.isLoading}/>
                    <div className="row">
                        <Popconfirm title="Prepočítať posledné obdobie?" onConfirm={this.handleRecalcReport}
                                    okText="Áno" cancelText="Nie">
                            <Button type="primary" className="report">Prepočítať</Button>
                        </Popconfirm>
                        <Popconfirm title="Odoslať report za posledné obdobie?" onConfirm={this.handleSendReport}
                                    okText="Áno" cancelText="Nie">
                            <Button type="primary" className="report">Odoslať report</Button>
                        </Popconfirm>
                        <Button type="primary" className="report" onClick={this.showPlatbaDriver}>Platba</Button>
                        <Drawer
                            title="Platba"
                            width={640}
                            closable={true}
                            placement="left"
                            onClose={this.onPlatbaDriverClose}
                            visible={this.state.platbaDrawer}
                        >
                            <NewCredit user={this.state.user} handleAfterSubmit={this.onNewCredit} isLoading={this.state.isLoading}/>
                        </Drawer>
                    </div>
                    <DepositTable report={this.state.deposit} user={this.state.user} isLoading={this.state.isLoading} />
                    <h2>Vystavené doklady</h2>
                    <MonthlyReportList monthlyReport={this.state.monthlyReport} isLoading={this.state.isLoading} isDetail/>
                    <h2>Osobný jedálny lístok</h2>
                        <Table
                            rowKey={(record) => record.order.id}
                            dataSource={this.state.orderServeList}
                            pagination={{pageSize: 5}}
                            columns={columns}
                            onChange={this.handleChange}
                            locale={{emptyText: 'V tomto období žiadne položky'}}
                            loading={{spinning: this.state.isLoading, indicator: <LoadingOutlined spin />}}
                        />
                </TabPane>
                <TabPane tab="Jedálny lístok" key="2">
                    <WeekPicker onChange={this.onWeekChange}
                                locale={skSK}
                                placeholder="Vyber týždeň" />
                    <UserMenuRovList isLoading={this.state.isLoading}
                                     user={this.state.user}
                                     week={this.state.week}
                                     year={this.state.year}
                    />
                </TabPane>
            </Tabs>
        );
    }
}

export default UserDetail;
