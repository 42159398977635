import React, {useState, useEffect} from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {Table, Button, notification} from "antd";
import moment from 'moment';
import "./Deposit.css";
import {depositRequest} from "../util/APIUtils";

const skLocale = require('moment/locale/sk');

function VyziadatDeposit({user}) {
    const [disabled, setDisabled] = useState();

    useEffect(() => setDisabled(false),
        [user]);

    if (user?.department !== 'ŠTUDENT') {
        return null;
    }

    function handleDepositRequest() {
        setDisabled(true);
        depositRequest({userId: user.id})
            .then(response => {
                notification.success({
                    message: 'Taxovia App',
                    description: 'Spracovane!'
                });
            })
            .catch(() => {
                notification.error({
                    message: 'Taxovia App',
                    description: 'Problém so spracovanim!'
                });
            })
        ;
    }

    return <div className="deposit-request-button-wrapper">
        <Button type="primary" danger disabled={disabled} onClick={handleDepositRequest}>Vyžiadať zloženie depozitu</Button>
    </div>
}

function DepositTable({report, user, isLoading}) {
    const columns = [{
        title: 'Vystavené',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text, record) => (text !== null ? moment(text).locale('sk', skLocale).format('D.M.YYYY HH:mm') : '')
    }, {
        title: 'Suma',
        dataIndex: 'amount',
        key: 'amount',
        render: (text, record) => (parseFloat(text).toFixed(2) + ' €')
    }, {
        title: 'VS',
        dataIndex: 'vs',
        key: 'vs'
    }, {
        title: 'ŠS',
        dataIndex: 'ss',
        key: 'ss'
    }, {
        title: 'Spárované',
        dataIndex: 'pairedDate',
        key: 'pairedDate',
        render: (text, record) => (text !== null ? moment(text).locale('sk', skLocale).format('D.M.YYYY') : '')
    }];

    return (
        <div>
            {report.length > 0 ?
                <>
                    <h2>Deposit</h2>
                    <Table
                        columns={columns}
                        rowKey={(record) => record.id}
                        dataSource={report}
                        pagination={{pageSize: 3}}
                        locale={{emptyText: 'Žiadny záznam'}}
                        loading={{spinning: isLoading, indicator: <LoadingOutlined spin/>}}
                    />
                </> : <VyziadatDeposit user={user}/>
            }
        </div>
    );
}

export default DepositTable;
