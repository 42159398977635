import React, {useState, useEffect} from 'react';
import LoadingIndicator from "../common/LoadingIndicator";
import {Table, Button, notification, Popconfirm} from "antd";
import {LoadingOutlined, QuestionCircleOutlined, WarningOutlined, RightSquareOutlined} from "@ant-design/icons";
import moment from "moment";
import {getDepositList, manualDepositToCredit, manualPairingDeposit} from "../util/APIUtils";

const skLocale = require('moment/locale/sk');

function DepositList() {
    const [isLoading, setIsLoading] = useState(false);
    const [depositList, setDepositList] = useState([]);
    const [disabled, setDisabled] = useState();

    const loadDepositList = () => {
        let promise = getDepositList();

        if (!promise) {
            return;
        }

        setIsLoading(true);
        promise
            .then(response => {
                setDepositList(response.slice());
                setIsLoading(false);
            }).catch(error => {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        loadDepositList()
    }, []);

    if (isLoading) {
        return <LoadingIndicator/>;
    }

    function handleOnClick(record) {
        setDisabled(true);
        manualPairingDeposit({depositId: record.id})
            .then(response => {
                setDisabled(false);
                notification.success({
                    message: 'Taxovia App',
                    description: response.message
                });
                loadDepositList();
            })
            .catch(error => {
                setDisabled(false);
                notification.error({
                    message: 'Taxovia App',
                    description: 'Problém s párovaním!'
                })
            })
    }

    function handleOnManualDepositToCreditClick(record) {
        setDisabled(true);
        manualDepositToCredit({depositId: record.id})
            .then(response => {
                setDisabled(false);
                notification.success({
                    message: 'Taxovia App',
                    description: response.message
                });
                loadDepositList();
            })
            .catch(error => {
                setDisabled(false);
                notification.error({
                    message: 'Taxovia App',
                    description: 'Problém s operaciou!'
                })
            })
    }

    function BtnManualPairing({disabled, onClick}) {
        return (
            <Popconfirm title="Určite mám potvrdiť príjem depozitu？"
                        onConfirm={onClick}
                        okText="Áno, potvrdzujem"
                        cancelText="Nie"
                        icon={<QuestionCircleOutlined/>}>
                <Button type="primary" danger disabled={disabled}><WarningOutlined/>Potvrdenie</Button>
            </Popconfirm>
        );
    }

    function BtnDepositMove({disabled, onClick}) {
        return (
            <Popconfirm title="Určite mám presunúť depozit na credit？"
                        onConfirm={onClick}
                        okText="Áno, potvrdzujem"
                        cancelText="Nie"
                        icon={<QuestionCircleOutlined/>}>
                <Button disabled={disabled}><RightSquareOutlined />Presun na kredit</Button>
            </Popconfirm>
        );
    }


    const columns = [{
        title: 'Priezvisko',
        dataIndex: ['user', 'surname'],
        key: 'surname'
    }, {
        title: 'Meno',
        dataIndex: ['user', 'name'],
        key: 'name'
    }, {
        title: 'Organizácia',
        dataIndex: ['user', 'organization'],
        key: 'organization',
        filters: [{
            text: 'SOŠ',
            value: 'SOS',
        }, {
            text: 'EGT',
            value: 'EGT',
        }, {
            text: 'Taxovia',
            value: 'Taxovia s.r.o.',
        }],
        filterMultiple: false,
        onFilter: (value, record) => record.user.organization.indexOf(value) === 0,
    }, {
        title: 'Depozit',
        dataIndex: ['amount'],
        key: 'amount'
    }, {
        title: 'VS',
        dataIndex: ['vs'],
        key: 'vs'
    }, {
        title: 'SS',
        dataIndex: ['ss'],
        key: 'ss'
    }, {
        title: 'Vyžiadaný',
        dataIndex: ['createdAt'],
        key: 'createdAt',
        render: (text, record) => (text !== null ? moment(text).locale('sk', skLocale).format('D.M.YYYY HH:mm') : '')
    }, {
        title: 'Deposit prijatý',
        dataIndex: ['pairedDate'],
        key: 'pairedDate',
        render: (text, record) => (text !== null ? moment(text).locale('sk', skLocale).format('D.M.YYYY') : '')
    }, {
        title: 'Doklad id',
        dataIndex: ['accountStatement', 'id'],
        key: 'accountStatement'
    }, {
        title: 'Akcia',
        key: 'akcia',
        render: (text, record) => !record.pairedDate ?
            <BtnManualPairing disabled={disabled} onClick={() => handleOnClick(record)}/> :
            <BtnDepositMove disabled={disabled} onClick={() => handleOnManualDepositToCreditClick(record)}/>
    }];

    return (
        <div>
            <h2>Depozit</h2>
            <Table
                rowKey={(record) => record.id}
                dataSource={depositList}
                columns={columns}
                locale={{emptyText: 'Nie je k dispozícii žiadny záznam!'}}
                loading={{spinning: isLoading, indicator: <LoadingOutlined spin/>}}
            />
        </div>
    );
}

export default DepositList;
