import React, {useEffect} from 'react';

function getSiteTitle(siteTitle) {
    if (siteTitle) {
        return siteTitle;
    }
    return process.env.REACT_APP_VERSION;
}
function Title({pageTitle, siteTitle}) {
    useEffect(() => {
        document.title = [pageTitle, getSiteTitle(siteTitle)]
            .filter(Boolean)
            .join(" | ");
    }, []);

    return null;
}

export default Title;
