import MonthlyReportList from './MonthlyReportList';

import React, {useEffect, useState} from 'react';
import {getMonthlyReport} from '../util/APIUtils';
import {notification} from 'antd';

function MonthlyReport({month, year}) {
    const [monthlyReport, setMonthlyReport] = useState([]);
    const [isLoading, setIsLoading] = useState();

    const loadMonthlyReportWithDate = (year, month) => {
        const monthlyReportRequest = {year, month};
        let promise = getMonthlyReport(monthlyReportRequest);

        if (!promise) {
            return;
        }

        setIsLoading(true);

        promise
            .then(response => {
                setMonthlyReport(response.slice());
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                notification.error({
                    message: 'Taxovia App',
                    description: 'Jaj, problém s mesačným reportom!'
                });
            });
    };

    useEffect(() => {
        loadMonthlyReportWithDate(year, month);
    }, [year, month])

    return (
        <MonthlyReportList monthlyReport={monthlyReport}
                           isLoading={isLoading}
                           setIsLoading={setIsLoading}
                           setMonthlyReport={setMonthlyReport}/>
    );
}

export default MonthlyReport;
