import React, {useState} from 'react';
import './NewMenuRov.css';
import skSK from 'antd/lib/date-picker/locale/sk_SK';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, DatePicker, Radio, notification, AutoComplete } from 'antd';
import {addMenu, searchMenuItems} from "../util/APIUtils";
import MenuTags from "./MenuTags";

const FormItem = Form.Item;
const {TextArea} = Input;

const dateFormat = 'DD.MM.YYYY';

const NewMenuRov = props => {
    const AntWrappedMenuForm = Form.create()(NewMenuForm);
    return (
        <div className="new-menu-container">
            <h1 className="page-title">Nové menu</h1>
            <div className="new-menu-content">
                <AntWrappedMenuForm
                    handleAfterSubmit={props.handleAfterSubmit} isLoading={props.isLoading}/>
            </div>
        </div>
    );
};

const NewMenuForm = props => {
    const [menuItems, setMenuItems] = useState([]);
    const [descriptionSource, setDescriptionSource] = useState([]);
    console.log(menuItems);

    const handleSubmit = event => {
        event.preventDefault();

        props.form.validateFields((err, values) => {
            if (!err) {
                const menuItemRequest = Object.assign({}, values);
                addMenu(menuItemRequest)
                    .then(response => {
                        notification.success({
                            message: 'Taxovia App',
                            description: "Ďakujeme! Úspešne ste pridali riadok do menu!",
                        });
                        props.handleAfterSubmit();
                    }).catch(error => {
                    if (error.status === 401) {
                        notification.error({
                            message: 'Taxovia App',
                            description: 'Nemáte právo niečo pridávať!'
                        });
                    } else {
                        notification.error({
                            message: 'Taxovia App',
                            description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                        });
                    }
                });
            }
        });
    };

    const onSelect = value => {
        props.form.setFieldsValue({description: value});
    };

    const handleSearch = value => {
        if (value.length < 3) {
            return false;
        }

        const rov = props.form.getFieldValue('rov');
        const itemId = props.form.getFieldValue('itemId');
        searchMenuItems(rov, value, itemId === '-' ? itemId : null)
            .then(response => {
                const options = response.menu
                    .reduce((acc, menuitem) => {
                        acc.push(menuitem.description);
                        return acc;
                    }, []);
                setDescriptionSource(!value ? [] : options);
                setMenuItems(!value ? [] : response);
            }).catch(error => {
            if (error.status === 401) {
                notification.error({
                    message: 'Taxovia App',
                    description: 'Nemáte právo niečo pridávať!'
                });
            } else {
                notification.error({
                    message: 'Taxovia App',
                    description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                });
            }
        });
    };

    const {getFieldDecorator} = props.form;
    return <Form onSubmit={handleSubmit} className="create-menu-form">
        <FormItem className="menu-form-row"
                  label="Dátum">
            {getFieldDecorator('date', {
                rules: [{
                    type: 'object',
                    required: true,
                    message: 'Musíte vybrať dátum!'
                }],
            })(
                <DatePicker
                    locale={skSK}
                    format={dateFormat}
                />
            )}
        </FormItem>
        <FormItem className="menu-form-row"
                  label="ROV">
            {getFieldDecorator('rov', {
                initialValue: 'OBED',
                rules: [{required: true, message: 'Musíte vybrať obdobie!'}],
            })(
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value="RANAJKY">Raňajky</Radio.Button>
                    <Radio.Button value="OBED">Obed</Radio.Button>
                    <Radio.Button value="VECERA">Večera</Radio.Button>
                </Radio.Group>
            )}
        </FormItem>
        <FormItem className="menu-form-row"
                  label="Menu">
            {getFieldDecorator('itemId', {
                initialValue: 'A',
                rules: [{required: true, message: 'Musíte vybrať menu!'}],
            })(
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value="A">A</Radio.Button>
                    <Radio.Button value="B">B</Radio.Button>
                    <Radio.Button value="C">C</Radio.Button>
                    <Radio.Button value="Š">Š</Radio.Button>
                    <Radio.Button value="-">Polievka</Radio.Button>
                </Radio.Group>
            )}
        </FormItem>
        <FormItem className="menu-form-row">
            {getFieldDecorator('description', {
                rules: [{required: true, message: 'Zadajte popis menu!'}],
            })(
                <AutoComplete
                    dataSource={descriptionSource}
                    onSelect={onSelect}
                    onSearch={handleSearch}
                >
                    <TextArea
                        placeholder="Vytvor popis menu"
                        style={{fontSize: '16px'}}
                        autoSize={{minRows: 5, maxRows: 10}}
                        name="description"/>
                </AutoComplete>
            )}
        </FormItem>
        <MenuTags tags={[]}/>
        <FormItem className="menu-form-row">
            <Button type="primary"
                    htmlType="submit"
                    size="large"
                    loading={props.isLoading}
                    className="create-menu-form-button">Vytvor menu</Button>
        </FormItem>
    </Form>;
};

export default NewMenuRov;
