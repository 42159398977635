import React, {useState} from 'react';
import {DatePicker} from 'antd';
import moment from 'moment';
import MonthlyReport from "./MonthlyReport";

const {MonthPicker} = DatePicker;

function MonthlyReportWithDate() {
    const [year, setYear] = useState(moment().subtract(1, 'months').year());
    const [month, setMonth] = useState(moment().subtract(1, 'months').month() + 1);

    function onChange(date, dateString) {
        if (date !== null) {
            setYear(date.year());
            setMonth(date.month());
        }
    }

    return (<div>
            <h2>Mesačný sumár</h2>
            <MonthPicker onChange={onChange}
                         defaultValue={moment([year, month - 1])} placeholder="Vyber mesiac"/>
            <MonthlyReport year={year} month={month}/>
        </div>
    );
}

export default MonthlyReportWithDate;
