import React, {Component} from 'react';
import './Display.css'

class Display extends Component {
    state = {
        value: this.props.value
    };

    render() {
        return (
            <div className='display-container'>
                {this.state.value}
            </div>
        );
    }
}

export default Display;