import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Table, Drawer, Badge, Calendar } from 'antd';
import './MenuRovList.css';
import {getAllMenuRovs} from "../util/APIUtils";
import moment from 'moment';
import MenuRovDelete from './MenuRovDelete';
import UpdateMenuRovButton from "./UpdateMenuRovButton";
import NewMenuRov from "./NewMenuRov";
import UpdateMenuRov from "./UpdateMenuRov";

const skLocale = require('moment/locale/sk');

const MenuRovList = props => {
    const [menuRovs, setMenuRovs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [editRecord, setEditRecord] = useState();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const loadMenuRovList = () => {
        let promise = getAllMenuRovs();

        if (!promise) {
            return;
        }

        setIsLoading(true);

        promise
            .then(response => {
                setIsLoading(false);
                setMenuRovs(response.menu.slice());
            }).catch(error => {
            setIsLoading(false);
        })
    };

    const handlerDeleteMenuItem = () => {
        loadMenuRovList();
    };

    useEffect(() => {
        if (props.isAuthenticated) {
            loadMenuRovList();
        }
    }, []);

    useEffect(() => {
        if (!props.isAuthenticated) {
            setMenuRovs([]);
            setIsLoading(false);
            setEditRecord(null);
            setDrawerOpen(false);
        } else {
            loadMenuRovList();
        }
    }, [props.isAuthenticated]);

    const showDrawer = () => {
        setDrawerOpen(true);
        setEditRecord(null);
    };

    const onCloseDrawer = () => {
        setDrawerOpen(false);
    };

    const handlerMenuRovUpdate = (menuRov) => {
        setDrawerOpen(true);
        setEditRecord(menuRov);
    };

    const handlerAfterMenuRovUpdate = () => {
        onCloseDrawer();
        loadMenuRovList();
    };

    const menuClassName = record => {
        return (record.order !== null ?
                'menu-ordered menu-' + record.rov.toLocaleLowerCase() +
                ' menu-itemid-' + record.itemId.toLocaleLowerCase() + ' menu-day-' + moment(record.date).day() :
                'menu-item menu-' + record.rov.toLocaleLowerCase() +
                ' menu-itemid-' + record.itemId.toLocaleLowerCase() + ' menu-day-' + moment(record.date).day()
        );
    };

    const columns = [{
        title: 'Dátum',
        dataIndex: 'date',
        key: 'date',
        className: 'menu-date',
        render: (text, record) => (moment(text).locale('sk', skLocale).format('dddd DD.MM.YYYY'))
    }, {
        title: 'ROV',
        dataIndex: 'rov',
        key: 'rov',
        className: 'menu-rov',
        filters: [{
            text: 'RAŇAJKY',
            value: 'RAŇAJKY',
        }, {
            text: 'OBED',
            value: 'OBED',
        }, {
            text: 'VEČERA',
            value: 'VEČERA',
        }],
        filterMultiple: true,
        onFilter: (value, record) => record.rov.indexOf(value) === 0,
    }, {
        title: 'Výber',
        dataIndex: 'itemId',
        key: 'itemId',
        className: 'menu-itemid',
        render: (text, record) => (
            <Badge count={record.countOrders} overflowCount={999}>
                <div className="itemid-badge">{text}</div>
            </Badge>
        )
    }, {
        title: 'Menu',
        dataIndex: 'description',
        key: 'description',
        className: 'menu-description'
    }, {
        title: 'Akcia',
        key: 'action',
        className: 'menu-action',
        render: (text, record) => (
            <div>
                <UpdateMenuRovButton menuRov={record} handlerMenuRovUpdate={handlerMenuRovUpdate}/>
                {record.countOrders > 0 ? null :
                    <MenuRovDelete menuRovId={record.id} handlerDeleteMenuItem={handlerDeleteMenuItem}/>}
            </div>
        ),
    }];

    return (
        <div className="menu-rov-container">
            {props.isAuthenticated ?
                <>
                    <Drawer
                        title='Jedálny lístok - menu'
                        width={640}
                        placement="left"
                        onClose={onCloseDrawer}
                        maskClosable={true}
                        open={drawerOpen}
                        className='drawer'
                    >
                        {!editRecord ?
                            <NewMenuRov handleAfterSubmit={loadMenuRovList} isLoading={isLoading}/> :
                            <UpdateMenuRov handleAfterSubmit={handlerAfterMenuRovUpdate}
                                           menuRov={editRecord}/>
                        }
                    </Drawer>
                    <Table
                        rowKey={(record) => record.id}
                        dataSource={menuRovs}
                        pagination={{pageSize: 15}}
                        columns={columns}
                        locale={{emptyText: 'Nie je k dispozícii ponukový list'}}
                        loading={{spinning: isLoading, indicator: <LoadingOutlined spin/>}}
                        rowClassName={(record, index) => menuClassName(record)}
                    />
                    <div>
                        <Button type="primary" onClick={showDrawer}>Pridať menu</Button>
                    </div>
                </> : <Calendar />}
        </div>
    );
};

export default withRouter(MenuRovList);
