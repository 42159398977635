import React, {useContext} from 'react';

const EMPTY = {
    onConfig: function () {
        console.error('Config function error');
    },
    useSmallOrder: false
};

const ConfigContext = React.createContext(EMPTY);

export const useConfigContext = () => useContext(ConfigContext);

export function ConfigProvider({config, children}) {
    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}
