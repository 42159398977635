import React from "react";
import {BackTop, Tabs} from "antd";
import {withRouter} from "react-router-dom";
import UserList from "../userlist/UserList";
import MonthlyReportWithDate from "../monthlyreport/MonthlyReportWithDate";
import CreditList from "../invoice/CreditList";
import './Accounting.css'
import AccountStatements from './AccountStatements';
import DepositList from "./DepositList";
import {useConfigContext} from "../common/ConfigContext";

function Accounting() {
    const {depositEnabled, fioEnabled} = useConfigContext();

    function onChangeTab(key) {
        console.log(key);
    }

    const items = [
        {
            label: "Zoznam stravníkov",
            key: "1",
            children: <UserList/>
        },
        {
            label: "Mesačný report",
            key: "2",
            children: <MonthlyReportWithDate/>
        },
        {
            label: "Kreditný systém",
            key: "3",
            children: <CreditList/>
        },
        depositEnabled && {
            label: "Depozit",
            key: "4",
            children: <DepositList/>
        },
        fioEnabled && {
            label: "Bankové pohyby",
            key: "5",
            children: <AccountStatements/>
        }
    ];

    return (
        <div className="accounting-container">
            <BackTop/>
            <Tabs defaultActiveKey="1" onChange={onChangeTab} items={items} />
        </div>
    );
}

export default withRouter(Accounting);
