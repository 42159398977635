import {Component} from "react";
import { QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, notification, Popconfirm } from "antd";
import React from "react";
import {deleteOrder} from "../util/APIUtils";

class UserOrderDeleteButton extends Component {

    handlerOrder = (event) => {
        event.preventDefault();
        deleteOrder(this.props.rov, this.props.date, this.props.userId)
            .then(response => {
                notification.success({
                    message: 'Taxovia App',
                    description: response.message,
                });
                this.props.handlerOrderMenuItem();
            }).catch(error => {
            if (error.status === 401) {
                notification.error({
                    message: 'Taxovia App',
                    description: 'Nemáte právo niečo odstraňovať!'
                });
            } else {
                notification.error({
                    message: 'Taxovia App',
                    description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                });
            }
        });
    };

    render() {
        return (
            <Popconfirm title="Určite mám zrušiť objednávku？"
                        onConfirm={this.handlerOrder}
                        okText="Áno, zrušiť"
                        cancelText="Nie"
                        icon={<QuestionCircleOutlined />}>
                <Button type="danger" ghost><WarningOutlined />Zrušiť</Button>
            </Popconfirm>
        );
    }
}

export default UserOrderDeleteButton;
