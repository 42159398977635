import React, {Component} from 'react';
import {serve} from '../util/APIUtils';
import './NewServeForm.css';
import { IdcardOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, notification } from 'antd';
import LoadingIndicator from "../common/LoadingIndicator";

const FormItem = Form.Item;

class NewServeForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
        this.onLoading = this.onLoading.bind(this);
    }

    onLoading(loading){
        // this.setState({
        //     isLoading: loading
        // });
    }

    render() {
        const AntWrappedServeForm = Form.create()(ServeForm)
        return (
            <div className="serve-form-container">
                <div className="serve-form-content">
                    <AntWrappedServeForm
                        onServe={this.props.onServe}
                        onServeStat={this.props.onServeStat}
                        onLoading={this.onLoading}
                        handlerOnError={this.props.handlerOnError}
                    />
                </div>
                {
                    this.state.isLoading ?
                        <LoadingIndicator />: null
                }
            </div>
        );
    }
}

class ServeForm extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
    }

    componentDidMount() {
        this.rfidCode.focus();
    }

    handleFocus() {
        this.props.form.resetFields();
        this.rfidCode.focus();
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.onLoading(true);
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const serveRequest = Object.assign({}, values);
                this.handleFocus();
                serve(serveRequest)
                    .then(response => {
                        notification.success({
                            message: 'Taxovia App',
                            description: response.message,
                        });
                        this.props.onServe(response);
                        this.props.onLoading(false);
                    }).catch(error => {
                    if (error.status === 404) {
                        notification.error({
                            message: 'Taxovia App',
                            description: error.message
                        });
                        this.props.handlerOnError(error.message);
                    } else {
                        notification.error({
                            message: 'Taxovia App',
                            description: error.message || 'Fúha! Niečo nie je v poriadku. Skúste ešte raz!'
                        });
                        this.props.handlerOnError(error.message);
                    }
                    this.props.onLoading(false);
                });
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form layout="inline" onSubmit={this.handleSubmit} className="serve-form">
                <FormItem>
                    {getFieldDecorator('rfidCode', {
                        rules: [{required: true, message: 'Zadajte kód rfid karty!'}],
                    })(
                        <Input
                            ref={(input) => {
                                this.rfidCode = input;
                            }}
                            prefix={<IdcardOutlined />}
                            size="large"
                            name="rfidCode"
                            placeholder="Kód RFID karty"
                        />
                    )}
                </FormItem>
                <FormItem>
                    <Button type="default"
                            htmlType="submit"
                            size="large"
                            className="serve-form-button">Výdaj</Button>
                </FormItem>
                <FormItem>
                    <Button type="dashed"
                            htmlType="button"
                            size="large"
                            className="serve-form-button-status"
                            onClick={this.props.onServeStat}>Stav</Button>
                </FormItem>
            </Form>
        );
    }
}

export default NewServeForm;
